import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, AlertIcon } from '@chakra-ui/react';

interface IAlert {
  message: string;
  status: 'info' | 'warning' | 'success' | 'error' | undefined;
}

const WBCustomAlert: FC<IAlert> = ({ message, status }) => {
  const { t } = useTranslation();
  return (
    <Alert status={status} mb={3}>
      <AlertIcon />
      {t(message)}
    </Alert>
  );
};

export default WBCustomAlert;
