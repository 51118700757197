import React, { FC, useCallback, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Flex, Text, Input, Textarea, Box, IconButton, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import { addEpisodeRequest, getProductListRequest } from 'redux/ProgramDetail/actions';
import WBEditableTable from 'components/WBEditableTable';
import WBSelect from 'components/WBSelect';
import EditableCell from 'components/WBEditableTable/WBEditableCell';
import { productList } from 'redux/ProgramDetail/selectors';
import Previews from 'components/WBDropzonePreview';
import { computeChecksumMd5 } from 'utils';
import { AiFillDelete } from 'react-icons/ai';
import { BiArrowBack } from 'react-icons/bi';
import { palette } from 'theme/theme';
import { isEmpty } from 'lodash';
import WBContainer from 'components/WBContainer';
import { history } from 'routes/history';
import { addMonths } from 'date-fns';

interface IWBModal {
  onClose: () => void;
  isOpen: boolean;
  modalTitle: string;
  id: number;
  type?: string;
  rows: {
    id: number;
    name: string;
    description: string;
    start_at: Date;
    icon_url: string;
    end_at: Date;
    program_id: number;
    video_url: string;
    placement: {
      product_id: number;
      owner_id: number;
      send_at: string;
      notification_type_id: number;
      title: string;
      description: string;
    }[];
  };
}

const initialStateRows = {
  id: 0,
  name: '',
  description: '',
  start_at: new Date() || 0,
  end_at: new Date() || 0,
  program_id: 0,
  video_url: '',
  icon_url: '',
  placement: [
    {
      product_id: 0,
      owner_id: 0,
      send_at: '',
      notification_type_id: 0,
      title: '',
      description: '',
    },
  ],
};

const AddEpisodePage: FC<IWBModal> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = useParams();

  const products = useSelector(productList);

  const [data, setData] = useState(initialStateRows);
  const [files, setFiles] = useState<any>([]);
  const [rowdata, setRowData] = useState<any>([]);
  const [skipPageReset, setSkipPageReset] = useState<any>(false);

  const isFormValid = !!(
    data?.name &&
    data?.description &&
    data?.start_at &&
    data?.video_url &&
    (data?.icon_url || !isEmpty(files))
  );
  const isPlacementValid = (rowdata: any) => {
    if (!isEmpty(rowdata)) {
      return !!(
        rowdata[0]?.send_at &&
        rowdata[0]?.notification_type_id?.value &&
        rowdata[0]?.title &&
        rowdata[0]?.description &&
        rowdata[0]?.product_id?.value
      );
    } else {
      return false;
    }
  };
  const isFormValidWithPlacement = !!(
    data?.name &&
    data?.description &&
    data?.start_at &&
    data?.video_url &&
    (data?.icon_url || !isEmpty(files)) &&
    !isEmpty(rowdata) &&
    !!isPlacementValid(rowdata)
  );

  const isValidated = isFormValidWithPlacement && isFormValid;

  const chooseDay = useCallback(
    (e) => {
      setData({ ...data, start_at: e });
    },
    [data],
  );

  const onSetFile = useCallback(async (file) => {
    const blob = new Blob(file, { type: 'text/plain' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    const hash = await computeChecksumMd5(file);
    reader.onloadend = function () {
      const base64String = reader?.result?.toString().split(',')[1];
      setFiles({ file, base64String, hash });
    };
  }, []);

  const onRemoveFile = useCallback(() => {
    setFiles([]);
  }, []);

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  const sendAddEpisode = useCallback(() => {
    const end_at = new Date(data.start_at);
    end_at.setMonth(end_at.getMonth() + 4);

    const addEpisodeObject = {
      name: data?.name,
      description: data?.description,
      start_at: format(data?.start_at, 'dd-MM-yyyy HH:mm') as unknown as Date,
      end_at: addMonths(new Date(data?.start_at), 4) as unknown as Date,
      program_id: Number(param.id),
      video_url: data?.video_url,
      icon: {
        files: [
          {
            filename: files?.file[0]?.path,
            hash: files?.hash,
            filestream: files?.base64String,
          },
        ],
      },
    };

    const addTableJSON = rowdata?.map((sendTable: any) => {
      return {
        product_id: sendTable?.product_id?.value,
        owner_id: 1,
        send_at: sendTable?.send_at,
        notification_type_id: sendTable?.notification_type_id?.value,
        title: sendTable?.title,
        description: sendTable?.description,
      };
    });
    dispatch(addEpisodeRequest({ ...addEpisodeObject, placement: addTableJSON }));
    goBack();
  }, [files, param, data, dispatch, rowdata, goBack]);

  const controlForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const onAddRowClick = useCallback(() => {
    setRowData(rowdata.concat({ title: '', description: '', notification_type_id: '', product_id: '', send_at: '' }));
  }, [rowdata]);

  const updateMyData = useCallback((rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setRowData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  }, []);

  const onDeletePlacement = useCallback(
    (idx: any) => {
      let rows = [...rowdata];
      rows.splice(idx, 1);
      setRowData(rows);
    },
    [rowdata],
  );

  const notification_type_id = useMemo(
    () => [
      { label: 'BRAND', value: 1 },
      { label: 'LIKE', value: 2 },
      { label: 'SUGGESTION', value: 3 },
      { label: 'SPONSORSHIP', value: 4 },
    ],
    [],
  );

  useEffect(() => {
    setSkipPageReset(false);
    dispatch(getProductListRequest());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'title',
        Cell: EditableCell,
      },
      {
        Header: 'Description',
        accessor: 'description',
        Cell: (allprops: any) => {
          return <EditableCell type="textArea" {...allprops} />;
        },
      },
      {
        Header: 'Notification type',
        accessor: 'notification_type_id',
        Cell: ({ value: initialValue, row: { index }, column: { id } }) => {
          return (
            <WBSelect
              isMulti={false}
              name="notification_type_id"
              options={notification_type_id}
              onChange={(value) => updateMyData(index, id, value)}
              value={initialValue}
            />
          );
        },
      },
      {
        Header: 'Send At',
        accessor: 'send_at',
        Cell: (allprops: any) => {
          return <EditableCell maxWidth="100px" type="time" {...allprops} />;
        },
      },
      {
        Header: 'Products',
        accessor: 'product_id',
        Cell: ({ value: initialValue, row: { index }, column: { id } }) => {
          return (
            <WBSelect
              isMulti={false}
              name="products"
              options={products}
              onChange={(value) => updateMyData(index, id, value)}
              value={initialValue}
            />
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row: { index } }) => {
          return (
            <IconButton
              m={2}
              outline="0px"
              variant="outline"
              color={palette.POMEGRANATE}
              border="1px solid"
              borderColor={palette.POMEGRANATE}
              borderRadius="6px"
              aria-label="delete"
              maxW={25}
              icon={<AiFillDelete />}
              onClick={() => onDeletePlacement(index)}
            />
          );
        },
      },
    ],
    [notification_type_id, updateMyData, products, onDeletePlacement],
  );

  return (
    <WBContainer>
      <Flex alignItems="center" w="100%" backgroundColor="white" rounded={6} mb={5}>
        <IconButton
          outline="0px"
          variant="outline"
          color={palette.ORANGE}
          aria-label="delete"
          maxW={25}
          icon={<BiArrowBack />}
          onClick={goBack}
        />
        <Text padding={1} /* border="1px solid" */ color={palette.ORANGE} borderColor={palette.ORANGE} fontSize="xl">
          {t('common:GoBack')}
        </Text>
      </Flex>
      <Flex direction="column" mb={50}>
        <Text fontSize="xl">{t('common:Title')}*</Text>
        <Input name="name" value={data?.name} onChange={controlForm} outline="0px" mb={3} />
        <Text fontSize="xl">{t('common:Description')}*</Text>
        <Textarea
          minH={200}
          name="description"
          value={data?.description}
          onChange={controlForm}
          mb={5}
          alignItems="center"
          outline="0px"
        />
        <Text fontSize="xl">{t('common:Video')}*</Text>
        <Input name="video_url" value={data?.video_url} onChange={controlForm} outline="0px" mb={3} />

        <Text fontSize="xl">{t('common:Image')}*</Text>
        <Previews setFile={onSetFile} files={files?.file} removeFile={onRemoveFile} />
        {data?.icon_url && (
          <Flex alignSelf="center">
            <Image src={data?.icon_url} width="200px" height="200px" />
          </Flex>
        )}

        <Box mb={10} width="100%">
          <Text fontSize="xl">{t('common:Date')}*</Text>
          <DateTimePicker onChange={chooseDay} value={data?.start_at} />
        </Box>

        <Box>
          <Flex justifyContent="flex-end" mt={10} mb={10} w="100%" backgroundColor="white" rounded={6}>
            <Button onClick={onAddRowClick} variant="secondaryOutline" size="primaryOutlineSmall" width="300px">
              {t('AddNewPlacement')}
            </Button>
          </Flex>
          {!isEmpty(rowdata) && (
            <WBEditableTable
              className="stripedTable"
              columns={columns}
              data={rowdata}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
            />
          )}
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" w="100%">
        <Button
          onClick={sendAddEpisode}
          variant={isValidated ? 'secondaryOutline' : 'disabled'}
          size="primaryOutlineSmall"
          width="300px"
          isDisabled={!isValidated}
        >
          {t('common:Save')}
        </Button>
      </Flex>
    </WBContainer>
  );
};
export default AddEpisodePage;
