import React, { FC, useCallback } from 'react';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import { palette } from 'theme/theme';
import { useAuth } from 'auth/hooks/useAuth';
import { FiLogOut } from 'react-icons/fi';

const WBHeader: FC = () => {
  const { logout } = useAuth();

  const onLogout = useCallback(() => {
    logout();
  }, [logout]);

  return (
    <Box
      shadow="0 5px 10px 0px rgba(0, 0, 0, 0.15)"
      position="fixed"
      top={0}
      w="100%"
      zIndex={{ base: 9999, lg: 99 }}
      display="block"
    >
      <Flex as="nav" align="center" justify="center" wrap="wrap" pt="10px" bg={palette.WHITE} w="100%">
        <Flex w="100%" justify="space-between" minH="56px" p="0px 55px" mx="auto">
          <Box display="flex" alignSelf="center" justifyContent="center" flexGrow={1}></Box>
          <Flex justifyContent="flex-end" alignItems="center" w="100%" backgroundColor="white" rounded={6} mb={5}>
            <IconButton
              left={10}
              outline="0px"
              variant="outline"
              color={palette.POMEGRANATE}
              border="1px solid"
              borderColor={palette.POMEGRANATE}
              borderRadius="6px"
              aria-label="delete"
              maxW={25}
              icon={<FiLogOut />}
              onClick={onLogout}
            />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default WBHeader;
