import { createAction } from '@reduxjs/toolkit';
import {
  deleteProductRequestPayload,
  getAllProductListRequestPayload,
  postAddProductRequestPayload,
  getProductDetailRequestPayload,
  patchProductRequestPayload,
  getAllProductListSuccessPayload,
  getProductDetailSuccessPayload,
  getProductSubCategorySuccessPayload,
  getBrandListRequestPayload,
} from './types';

export const getAllProductListRequest = createAction<getAllProductListRequestPayload>('GET_ALL_PRODUCT_LIST_REQUEST');
export const getAllProductListSuccess = createAction<getAllProductListSuccessPayload>('GET_ALL_PRODUCT_LIST_SUCCESS');
export const getAllProductListFailed = createAction('GET_ALL_PRODUCT_LIST_FAILED');

export const deleteProductRequest = createAction<deleteProductRequestPayload>('DELETE_PRODUCT_REQUEST');
export const deleteProductSuccess = createAction('DELETE_PRODUCT_SUCCESS');
export const deleteProductFailed = createAction('DELETE_PRODUCT_FAILED');

export const postAddProductRequest = createAction<postAddProductRequestPayload>('POST_ADD_PRODUCT_REQUEST');
export const postAddProductSuccess = createAction('POST_ADD_PRODUCT_SUCCESS');
export const postAddProductFailed = createAction('POST_ADD_PRODUCT_FAILED');

export const getProductDetailRequest = createAction<getProductDetailRequestPayload>('GET_PRODUCT_DETAIL_REQUEST');
export const getProductDetailSuccess = createAction<getProductDetailSuccessPayload>('GET_PRODUCT_DETAIL_SUCCESS');
export const getProductDetailFailed = createAction('GET_PRODUCT_DETAIL_FAILED');

export const patchProductRequest = createAction<patchProductRequestPayload>('PATCH_PRODUCT_REQUEST');
export const patchProductSuccess = createAction('PATCH_PRODUCT_SUCCESS');
export const patchProductFailed = createAction('PATCH_PRODUCT_FAILED');

export const getBrandListRequest = createAction('GET_BRAND_LIST_REQUEST');
export const getBrandListSuccess = createAction<getBrandListRequestPayload>('GET_BRAND_LIST_SUCCESS');
export const getBrandListFailed = createAction('GET_BRAND_LIST_FAILED');

export const getSubCategoryListRequest = createAction('GET_SUBCATEGORY_LIST_REQUEST');
export const getSubCategoryListSuccess =
  createAction<getProductSubCategorySuccessPayload>('GET_SUBCATEGORY_LIST_SUCCESS');
export const getSubCategoryListFailed = createAction('GET_SUBCATEGORY_LIST_FAILED');
