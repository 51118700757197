import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Flex, Text, Input, Textarea, Box, Image, Heading, Checkbox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { useDispatch, useSelector } from 'react-redux';
import WBSelect from 'components/WBSelect';
import Previews from 'components/WBDropzonePreview';
import { computeChecksumMd5, isValidURL } from 'utils';
import { isEmpty } from 'lodash';
import WBContainer from 'components/WBContainer';
import { history } from 'routes/history';
import {
  getBrandListRequest,
  getProductDetailRequest,
  getSubCategoryListRequest,
  patchProductRequest,
} from 'redux/ProductList/actions';
import {
  allBrandList,
  allSubCategoryList,
  allSubCategoryLoading,
  loadingProductDetails,
  productDetails,
} from 'redux/ProductList/selectors';
import WBLoader from 'components/WBLoader';
import { ISubcategory } from 'redux/ProductList/types';

interface IData {
  brand?: string;
  details?: string;
  id?: number;
  name?: string;
  subcategory?: ISubcategory;
  trend?: boolean;
  product_url?: string;
  icon_url?: string;
}

const EditProductPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = useParams();

  const loadingSubCategory = useSelector(allSubCategoryLoading);
  const loadingProductDetail = useSelector(loadingProductDetails);
  const productDetail = useSelector(productDetails);
  const brands = useSelector(allBrandList);
  const subCategoryList = useSelector(allSubCategoryList);

  const [data, setData] = useState<IData>();
  const [files, setFiles] = useState<any>([]);
  const [subCategories, setSelectedSubCategory] = useState<{ label: string; value: number }>();
  const [isTrend, setIsTrend] = useState<boolean>(false);

  const isValidated = !!(
    data?.name &&
    subCategories &&
    data?.brand &&
    data?.product_url &&
    data?.details &&
    (data?.icon_url || !isEmpty(files))
  );

  const onSetFile = useCallback(async (file) => {
    const blob = new Blob(file, { type: 'text/plain' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    const hash = await computeChecksumMd5(file);
    reader.onloadend = function () {
      const base64String = reader?.result?.toString().split(',')[1];
      setFiles({ file, base64String, hash });
    };
  }, []);

  const onRemoveFile = useCallback(() => {
    setFiles([]);
  }, []);

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  const sendEditImage = useCallback(() => {
    if (!isEmpty(files.file)) {
      return {
        files: [
          {
            filename: !isEmpty(!files.file) && files?.file[0]?.path,
            hash: files?.hash,
            filestream: files?.base64String,
          },
        ],
      };
    } else {
      return null;
    }
  }, [files?.base64String, files.file, files?.hash]);

  const sendEditProduct = useCallback(() => {
    const addProduct = {
      id: param?.id,
      editProduct: {
        name: data?.name,
        product_subcategory_id: subCategories?.value,
        brand: data?.brand,
        url: data?.product_url,
        details: data?.details,
        trend: isTrend ? 1 : 0,
        icon: sendEditImage(),
      },
    };
    dispatch(patchProductRequest(addProduct));
    goBack();
  }, [
    dispatch,
    goBack,
    data?.brand,
    data?.details,
    data?.name,
    data?.product_url,
    isTrend,
    param.id,
    sendEditImage,
    subCategories,
  ]);

  const controlForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const onSelectSubCategory = useCallback((selectedSubCategory) => {
    setSelectedSubCategory(selectedSubCategory);
  }, []);

  const onFactorySelect = useCallback((list: { id: number; name: string }[]) => {
    return list?.map((l: { id: number; name: string }) => {
      return {
        value: l?.id,
        label: l?.name,
      };
    });
  }, []);

  const checkUrl = useCallback(() => {
    return isValidURL(data?.product_url!);
  }, [data?.product_url]);

  const checkBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsTrend(e.target.checked);
    },
    [setIsTrend],
  );

  useEffect(() => {
    if (param.id) {
      const { id } = param;
      dispatch(getProductDetailRequest({ id }));
    }
    dispatch(getBrandListRequest());
    dispatch(getSubCategoryListRequest());
  }, [dispatch, param?.id, param]);

  useEffect(() => {
    setData(productDetail);
    setIsTrend(productDetail?.trend);
  }, [productDetail]);

  useEffect(() => {
    const newSubCategory = {
      label: productDetail?.subcategory?.name,
      value: productDetail?.subcategory?.id,
    };

    if (productDetail) {
      setSelectedSubCategory(newSubCategory);
    }
  }, [productDetail?.subcategory?.id, productDetail?.subcategory?.name, productDetail, brands]);

  if (loadingProductDetail) {
    return <WBLoader />;
  }

  return (
    <WBContainer>
      <Heading display="block" mb={6}>
        {t('common:AddProduct')}
      </Heading>
      <Flex w="80%" direction="column" backgroundColor="white">
        <Flex direction="column" mb={50}>
          <Text fontSize="xl">{t('common:Title')}*</Text>
          <Input name="name" value={data?.name} onChange={controlForm} outline="0px" mb={3} />
          <Text fontSize="xl">{t('common:SubCategory')}*</Text>
          <Box mb={5}>
            {!loadingSubCategory && !isEmpty(subCategoryList) ? (
              <WBSelect
                isMulti={false}
                name="product_subcategory_id"
                options={onFactorySelect(subCategoryList)}
                onChange={onSelectSubCategory}
                value={subCategories}
              />
            ) : (
              <WBLoader />
            )}
          </Box>
          <Text fontSize="xl">{t('common:Brand')}*</Text>
          <Input name="brand" value={data?.brand} onChange={controlForm} outline="0px" mb={3} />
          <Text fontSize="xl">{t('common:ProductUrl')}*</Text>
          <Input
            name="product_url"
            onBlur={checkUrl}
            value={data?.product_url}
            onChange={controlForm}
            outline="0px"
            mb={3}
          />
          {data?.product_url && !isValidURL(data?.product_url) && (
            <Text fontSize="sm" pt="5px" color="red">
              {t('ErrorURLHTTP')}
            </Text>
          )}

          <Text fontSize="xl">{t('common:Description')}*</Text>
          <Textarea
            minH={200}
            name="details"
            value={data?.details}
            onChange={controlForm}
            mb={5}
            alignItems="center"
            outline="0px"
          />
          <Text fontSize="xl">{t('common:Trend')}</Text>

          <Checkbox mb={10} onChange={checkBox} minW="max-content" size="lg" colorScheme="orange" isChecked={isTrend} />

          <Text fontSize="xl">{t('common:Image')}*</Text>

          <Previews setFile={onSetFile} files={files?.file} removeFile={onRemoveFile} />
          {data?.icon_url && isEmpty(files.file) && (
            <Flex alignSelf="center">
              <Image src={data?.icon_url} width="200px" height="200px" />
            </Flex>
          )}
          <Flex mt={10} justifyContent="flex-end" w="100%" backgroundColor="white" rounded={6}>
            <Button
              onClick={sendEditProduct}
              variant={isValidated ? 'secondaryOutline' : 'disabled'}
              size="primaryOutlineSmall"
              width="300px"
              isDisabled={!isValidated}
            >
              {t('common:Save')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </WBContainer>
  );
};
export default EditProductPage;
