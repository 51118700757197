import { createSelector } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import type { RootState } from 'redux/reducers';

const allProgramListSelector = (state: RootState) => state.programList;

export const allProgramListLoading = createSelector(
  allProgramListSelector,
  (programListState) => programListState.loading,
);

export const allProgramList = createSelector(allProgramListSelector, (programListState) =>
  uniqBy(programListState.episodes, 'program_id'),
);

export const allProgramListInNotification = createSelector(allProgramListSelector, (programListState) =>
  uniqBy(programListState.episodes, 'id'),
);

export const allProgramListTotalElements = createSelector(
  allProgramListSelector,
  (programListState) => programListState.total_elements,
);

export const allProgramListCurrentPage = createSelector(
  allProgramListSelector,
  (programListState) => programListState.currentPage,
);

export const deleteProgramLoading = createSelector(
  allProgramListSelector,
  (programListState) => programListState.loadingDeleteProgram,
);
