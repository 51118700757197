import { APIClient } from 'common/utils';
import {
  deleteNotificationRequestPayload,
  editNotificationPayload,
  filterNotificationRequestPayload,
  getAllNotificationListRequestPayload,
  getNotificationRequestPayload,
  postNewNotificationByIdPayload,
  postNewNotificationPayload,
} from './types';

export async function getAllNotificationList({
  limit = 10,
  page = 1,
}: getAllNotificationListRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.get(
      `${process.env.REACT_APP_BASE_API_URL}/product-placement-live?limit=${limit}&page=${page}`,
    );
    return resp.data;
  } catch (error) {
    console.error('getAllNotificationList - Error: ', error);
    throw error;
  }
}
export async function getFilteredNotification(payload: filterNotificationRequestPayload): Promise<Response> {
  const { id, limit, page } = payload;
  try {
    const resp = await APIClient.get(
      `${process.env.REACT_APP_BASE_API_URL}/product-placement-live?program_id=${id}&limit=${limit}&page=${page}`,
    );
    return resp.data;
  } catch (error) {
    console.error('getFilteredNotification - Error: ', error);
    throw error;
  }
}
export async function addNewNotification(payload: postNewNotificationPayload): Promise<Response> {
  try {
    const resp = await APIClient.post(`${process.env.REACT_APP_BASE_API_URL}/product-placement-live`, {
      ...payload,
    });
    return resp.data;
  } catch (error) {
    console.error('addNewNotification - Error: ', error);
    throw error;
  }
}
export async function editNotification(payload: editNotificationPayload): Promise<Response> {
  const { id, ...others } = payload;
  try {
    const resp = await APIClient.patch(`${process.env.REACT_APP_BASE_API_URL}/product-placement-live/${id}`, {
      ...others,
    });
    return resp.data;
  } catch (error) {
    console.error('editNotification - Error: ', error);
    throw error;
  }
}
export async function addNewNotificationById({ id }: postNewNotificationByIdPayload): Promise<Response> {
  try {
    const resp = await APIClient.post(
      `${process.env.REACT_APP_BASE_API_URL}/product_placement_live_notification/${id}`,
    );
    return resp.data;
  } catch (error) {
    console.error('addNewNotification - Error: ', error);
    throw error;
  }
}

export async function getNotification({ id }: getNotificationRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/product-placement-live/${id}`);
    return resp.data;
  } catch (error) {
    console.error('getNotification - Error: ', error);
    throw error;
  }
}

export async function deleteNotification({ id }: deleteNotificationRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.post(`${process.env.REACT_APP_BASE_API_URL}/product-placement/${id}/remove-placement`);
    return resp.data;
  } catch (error) {
    console.error('deleteNotification - Error: ', error);
    throw error;
  }
}
