export const AppRoutes = {
  LoginPage: '/login',
  ProgramList: '/programs',
  AddProgramPage: '/programs/add',
  EditProgramPage: '/programs',
  Notification: '/notifications',
  CreateNotification: '/create-notifications',
  EditNotification: '/edit-notifications',
  ProductList: '/products',
  EditProductPage: '/products',
  AddProductPage: '/products/add',
  AddEpisode: '/create-episode',
  EditEpisode: '/edit-episode',
};
