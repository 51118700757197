export const InputStyles = {
  // The styles all button have in common
  baseStyle: {
    outline: '0px',
    width: '300px',
    alignItems: 'center',
    mb: '3px',
    borderColor: 'disabled',
  },
  sizes: {
    primaryBig: {
      fontSize: 'lg',
      h: '54px',
    },
    primarySmall: {
      fontSize: 'lg',
      h: '40px',
    },
    primaryOutlineBig: {
      fontSize: 'lg',
      h: '54px',
    },
    primaryOutlineSmall: {
      fontSize: 'lg',
      h: '40px',
      'min-width': '140px',
    },
  },
  // Two variants: primary and outline
  variants: {
    disabled: {
      bg: 'white',
      color: 'disabled',
      borderWidth: '2px',
      borderColor: 'disabled',
    },

    primary: {
      bg: 'primary',
      color: 'white',
      px: '120px',
    },
    primaryOutline: {
      bg: 'white',
      color: 'primary',
      borderWidth: '2px',
      borderColor: 'primary',
    },
    secondaryOutline: {
      bg: 'white',
      color: 'secondary',
      borderWidth: '2px',
      borderColor: 'secondary',
      _hover: {
        bg: 'secondary',
        color: 'white',
        borderWidht: '2px',
      },
    },
    dangerOutline: {
      bg: 'white',
      color: 'dangerColor',
      borderWidth: '2px',
      borderColor: 'dangerColor',
      _hover: {
        bg: 'dangerColor',
        color: 'white',
        borderWidht: '2px',
      },
    },
    btnLink: {
      bg: 'trasparent',
      color: 'secondary',
      px: '120px',
    },
  },
  // The default size and variant values
  /*  defaultProps: {
      size: 'lg',
      variant: 'primary',
    }, */
};
