import * as React from 'react';
import { FC, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import { CSSReset, useToast, Flex } from '@chakra-ui/react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { messageHandlerFullInfo } from 'redux/messageHandler/selectors';
import { messageHandlerReset } from 'redux/messageHandler/actions';
import WBHeader from 'components/WBHeader';
import ScrollToTop from 'components/ScrollToTopOnMount';
import LoginPage from 'pages/LoginPage';
import WBDrawer from 'components/WBDrawer';
import ProgramList from 'pages/ProgramListPage';
import AddProgramPage from 'pages/AddProgramPage';
import EditProgramPage from 'pages/SingleProgramPage';
import { PrivateRoute } from 'routes/privateRoute';
import { useAuth } from 'auth/hooks/useAuth';
import { AppRoutes } from 'routes/routesList';
import CreateNotification from 'pages/CreateNotificationPage';
import Notification from 'pages/NotificationPage';
import AddEpisodePage from 'pages/AddEpisodePage';
import EditEpisodePage from 'pages/EditEpisodePage';
import ProductList from 'pages/ProductsListPage';
import EditProductPage from 'pages/EditProductPage';
import AddProductPage from 'pages/AddProductPage';

const App: FC = () => {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const toast = useToast();
  const hasGeneralMessage = useSelector(messageHandlerFullInfo);
  const font = new FontFaceObserver('Nunito Sans');
  const html = document.documentElement;

  useLayoutEffect(() => {
    html.classList.add('fonts-loading');

    font
      .load()
      .then(function () {
        html.classList.remove('fonts-loading');
        html.classList.add('fonts-loaded');
      })
      .catch(function () {
        html.classList.remove('fonts-loading');
        html.classList.add('fonts-failed');
      });
  });

  useEffect(() => {
    if (hasGeneralMessage?.message) {
      toast({
        description: hasGeneralMessage.description,
        status: hasGeneralMessage.type,
        duration: 3000,
        isClosable: true,
      });

      setTimeout(() => {
        dispatch(messageHandlerReset());
      }, 3000);
    }
  }, [hasGeneralMessage, toast, dispatch]);

  return (
    <Flex height="100%" width="100%">
      <CSSReset />
      <>
        {isAuthenticated && <WBDrawer />}
        {isAuthenticated && <WBHeader />}
        <ScrollToTop />
        <Switch>
          {/* <Route exact path="/" component={LoginPage} /> */}
          <Route exact path={AppRoutes.LoginPage} component={LoginPage} />
          <PrivateRoute exact path={AppRoutes.ProgramList} component={ProgramList} />
          <Route exact path="/">
            <Redirect to={AppRoutes.ProgramList} />
          </Route>
          <PrivateRoute exact path={AppRoutes.AddProgramPage} component={AddProgramPage} />
          <PrivateRoute exact path={`${AppRoutes.EditProgramPage}/:id/edit`} component={EditProgramPage} />
          <PrivateRoute exact path={AppRoutes.Notification} component={Notification} />
          <PrivateRoute exact path={`${AppRoutes.EditNotification}/:id/edit`} component={CreateNotification} />
          <PrivateRoute exact path={AppRoutes.CreateNotification} component={CreateNotification} />
          <PrivateRoute exact path={AppRoutes.ProductList} component={ProductList} />
          <PrivateRoute exact path={`${AppRoutes.EditProductPage}/:id/edit`} component={EditProductPage} />
          <PrivateRoute exact path={`${AppRoutes.AddProductPage}`} component={AddProductPage} />
          <PrivateRoute
            exact
            path={`${AppRoutes.EditProgramPage}/:id${AppRoutes.AddEpisode}`}
            component={AddEpisodePage}
          />
          <PrivateRoute
            exact
            path={`${AppRoutes.EditProgramPage}/:id${AppRoutes.EditEpisode}/:episodeId`}
            component={EditEpisodePage}
          />
        </Switch>
      </>
    </Flex>
  );
};

export default App;
