import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  Tooltip,
  IconButton,
  Text,
  Box,
} from '@chakra-ui/react';
import PUCardContainer from 'components/PUCardContainer';
import WBLoader from 'components/WBLoader';
// import { palette } from 'apps/pu/theme/theme';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlexLayout, useTable, usePagination, HeaderColumn } from 'react-table';
import { palette } from 'theme/theme';
// import PUCardContainer from '../PUCardContainer';

interface TableProps {
  columns: HeaderColumn[];
  data: any; // ooohhhh yeah!!! Check TableProps, but this component is dynamic so the types could be different!!!
  loading: boolean;
  totalPages: number;
  currentPage: number;
  fetchData?: ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => void;
  noPagination?: boolean;
  className?: string;
  withContainer?: boolean;
}

const PUTable: FC<TableProps> = ({
  columns,
  data,
  fetchData,
  totalPages,
  currentPage,
  loading = true,
  noPagination = false,
  className = '',
  withContainer = false,
}) => {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 100, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      // maxWidth: 200, // maxWidth is only used as a limit for resizing
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      defaultColumn,
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: itemsPerPage,
      },
      manualPagination: true,
      autoResetPage: false,
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: Math.ceil(totalPages / itemsPerPage),
    },

    usePagination,
    useFlexLayout,
  );

  const onChangePage = useCallback(
    (value: React.ChangeEvent<HTMLInputElement> | string | number) => {
      const page = value ? Number(value) - 1 : 0;
      gotoPage(page);
    },
    [gotoPage],
  );

  const onChangeItemsPerPage = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      setPageSize(Number(e.target.value));
    },
    [setPageSize],
  );

  useEffect(() => {
    fetchData &&
      fetchData({
        pageIndex: pageIndex,
        pageSize,
      });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    setItemsPerPage(pageSize);
    gotoPage(0);
  }, [pageSize, gotoPage]);

  if (!loading && isEmpty(data)) {
    return (
      <PUCardContainer backgroundColor="grey4" noBorders noShadow>
        <Flex w="100%" justifyContent="center">
          {/* <Text textStyle="cardDetailsDynamicContent">{t('web.pu.Pagination.NoData')}</Text> */}
          <Text textStyle="cardDetailsDynamicContent">{t('Pagination.NoData')}</Text>
        </Flex>
      </PUCardContainer>
    );
  }

  return (
    <Box position="relative" w="100%" minHeight="100px">
      {loading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          backgroundColor="shadowGreyCard4"
        >
          <WBLoader />
        </Flex>
      )}

      <PUCardContainer
        noShadow
        noBorders
        backgroundColor={withContainer ? 'grey6' : 'transparent'}
        padding={!withContainer ? 0 : '24px'}
      >
        <Box {...getTableProps()} className={className} as="table">
          <Box textAlign="left" as="thead">
            {headerGroups.map((headerGroup) => {
              return (
                <Box {...headerGroup.getHeaderGroupProps()} as="tr">
                  {headerGroup.headers.map((column) => {
                    return (
                      <Box
                        {...column.getHeaderProps()}
                        as="th"
                        minWidth={{ base: 'max-content', lg: 'auto' }}
                        fontSize="sm"
                        paddingX="23px"
                      >
                        <Flex minWidth={{ base: 'auto', lg: 'max-content' }} flexDirection="row" alignItems="center">
                          {column.render('Header')}
                        </Flex>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>

          <Box {...getTableBodyProps()} as="tbody">
            {page.map((row) => {
              prepareRow(row);
              return (
                <Box
                  backgroundColor="white"
                  {...row.getRowProps()}
                  boxShadow={withContainer ? `0 0 5px 2px ${palette.SILVER}` : `0 0 5px 2px ${palette.CLOUDS}`}
                  as="tr"
                >
                  {row.cells.map((cell) => {
                    return (
                      <Box minWidth="100px" {...cell.getCellProps()} as="td">
                        {cell.render('Cell')}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        </Box>
      </PUCardContainer>

      {!noPagination && (
        <Flex justifyContent={{ xs: 'center', lg: 'space-between' }} my={4} alignItems="center">
          <Flex display={{ xs: 'none', lg: 'flex' }}>
            <Tooltip label={t('common:FirstPage')}>
              <IconButton
                aria-label={t('common:FirstPage')}
                onClick={() => onChangePage(0)}
                isDisabled={currentPage === 0 || !canPreviousPage}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
              />
            </Tooltip>
            <Tooltip label={t('common:Previous')}>
              <IconButton
                aria-label={t('common:Previous')}
                onClick={previousPage}
                isDisabled={currentPage === 0 || !canPreviousPage}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
            </Tooltip>
          </Flex>
          <Flex alignItems="center">
            <Text flexShrink={0} mr={8} fontSize="md">
              {t('common:Page')}
              <Text fontWeight="bold" as="span" mx="3px">
                {pageIndex + 1}
              </Text>
              {t('common:Of')}
              <Text fontWeight="bold" as="span" mx="3px">
                {pageOptions.length}
              </Text>
            </Text>

            <Text flexShrink={0} fontSize="md">
              {t('common:GoToPage')}
            </Text>
            <NumberInput
              ml={2}
              mr={8}
              w={28}
              min={1}
              max={pageOptions.length}
              onChange={onChangePage}
              defaultValue={pageIndex + 1}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            <Text flexShrink={0} fontSize="md" mr="5px">
              {t('common:ItemsPerPage')}
            </Text>
            <Select w={32} value={pageSize} onChange={onChangeItemsPerPage}>
              {[1, 5, 10, 20, 30].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </Select>
          </Flex>

          <Flex display={{ xs: 'none', lg: 'flex' }}>
            <Tooltip label={t('common:NextPage')}>
              <IconButton
                aria-label={t('common:NextPage')}
                onClick={nextPage}
                isDisabled={!canNextPage}
                icon={<ChevronRightIcon h={6} w={6} />}
              />
            </Tooltip>
            <Tooltip label={t('common:LastPage')}>
              <IconButton
                aria-label={t('common:LastPage')}
                onClick={() => onChangePage(pageCount)}
                isDisabled={!canNextPage}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
              />
            </Tooltip>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};
export default PUTable;
