import { APIClient } from 'common/utils';
import {
  getProgramDetailRequestPayload,
  postAddProgramRequestPayload,
  patchProgramRequestPayload,
  patchEpisodeRequestPayload,
  deleteEpisodeRequestPayload,
  addEpisodePayloadRequest,
  postAddTableJSONRequestPayload,
  patchTableJSONRequestPayload,
  deleteTableJSONRequestPayload,
} from './types';

export async function getProgramDetail({ id }: getProgramDetailRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/programs/${id}`);
    return resp.data;
  } catch (error) {
    console.error('getProgramDetail - Error: ', error);
    throw error;
  }
}
export async function postAddProgram(program: postAddProgramRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.post(`${process.env.REACT_APP_BASE_API_URL}/programs`, { ...program });
    return resp.data;
  } catch (error) {
    console.error('postAddProgram - Error: ', error);
    throw error;
  }
}
export async function patchProgram(program: patchProgramRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.patch(`${process.env.REACT_APP_BASE_API_URL}/programs/${program.id}`, { ...program });
    return resp.data;
  } catch (error) {
    console.error('patchProgram - Error: ', error);
    throw error;
  }
}
export async function getProgramIcon(): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/program-icon`);
    return resp.data;
  } catch (error) {
    console.error('getProgramIcon - Error: ', error);
    throw error;
  }
}
export async function getEpisodeIcon(): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/episode-icon`);
    return resp.data;
  } catch (error) {
    console.error('getEpisodeIcon - Error: ', error);
    throw error;
  }
}
export async function getBroadCastList(): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/broadcast`);
    return resp.data;
  } catch (error) {
    console.error('getBroadCastList - Error: ', error);
    throw error;
  }
}
export async function deleteEpisode({ id }: deleteEpisodeRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.patch(`${process.env.REACT_APP_BASE_API_URL}/episodes/${id}/delete`);
    return resp.data;
  } catch (error) {
    console.error('deleteEpisode - Error: ', error);
    throw error;
  }
}
export async function patchEpisode(episode: patchEpisodeRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.patch(`${process.env.REACT_APP_BASE_API_URL}/episodes/${episode.id}`, {
      ...episode.episode,
    });
    return resp.data;
  } catch (error) {
    console.error('patchEpisode - Error: ', error);
    throw error;
  }
}
export async function addEpisode(episode: addEpisodePayloadRequest): Promise<Response> {
  try {
    const resp = await APIClient.post(`${process.env.REACT_APP_BASE_API_URL}/episodes`, { ...episode });
    return resp.data;
  } catch (error) {
    console.error('patchProgram - Error: ', error);
    throw error;
  }
}
export async function getProducts(): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/products`);
    return resp.data;
  } catch (error) {
    console.error('getProducts - Error: ', error);
    throw error;
  }
}
export async function postTableJson(tableJson: postAddTableJSONRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.post(`${process.env.REACT_APP_BASE_API_URL}/product-placement`, { ...tableJson });
    return resp.data;
  } catch (error) {
    console.error('postTableJson - Error: ', error);
    throw error;
  }
}
export async function patchTableJson(tableJson: patchTableJSONRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.patch(
      `${process.env.REACT_APP_BASE_API_URL}/product-placement/${tableJson.episode_id}`,
      { ...tableJson },
    );
    return resp.data;
  } catch (error) {
    console.error('postTableJson - Error: ', error);
    throw error;
  }
}

export async function deleteTableJson({ id }: deleteTableJSONRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.post(`${process.env.REACT_APP_BASE_API_URL}/product-placement/${id}/remove-placement`);
    return resp.data;
  } catch (error) {
    console.error('deleteTableJson - Error: ', error);
    throw error;
  }
}
