import { Button, IconButton } from '@chakra-ui/button';
import { Box, Flex } from '@chakra-ui/layout';
import { Td, Th, Tr } from '@chakra-ui/react';
import { Table, Tbody, Thead } from '@chakra-ui/table';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai';
import { IoIosAdd } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { useTable, usePagination } from 'react-table';
import { deleteEpisodeRequest } from 'redux/ProgramDetail/actions';
import { programDetail } from 'redux/ProgramDetail/selectors';
import { useParams } from 'react-router-dom';
import { palette } from 'theme/theme';
import format from 'date-fns/format';
import { history } from 'routes/history';
import { AppRoutes } from 'routes/routesList';

export const ProgramEpisodesList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const program = useSelector(programDetail);

  const param = useParams();

  const goToCreateEpisode = useCallback(() => {
    history.push(`${AppRoutes.EditProgramPage}/${Number(param.id)}${AppRoutes.AddEpisode}`);
  }, [param.id]);

  const goToEditEpisode = useCallback(
    (programId) => {
      history.push(`${AppRoutes.EditProgramPage}/${Number(param.id)}${AppRoutes.EditEpisode}/${programId}`);
    },
    [param?.id],
  );

  const onRemoveEpisode = useCallback(
    (id: number) => {
      dispatch(deleteEpisodeRequest({ id, programId: param?.id }));
    },
    [dispatch, param.id],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'episode name',
        accessor: 'name',
      },
      {
        Header: 'start at',
        Cell: ({ row }) => <Box>{format(new Date(row.original.start_at), 'dd-MM-yyyy')}</Box>,
      },
      {
        Header: 'end at',
        Cell: ({ row }) => <Box>{format(new Date(row.original.end_at), 'dd-MM-yyyy')}</Box>,
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          // cell.row.original.id
          <>
            <IconButton
              onClick={() => goToEditEpisode(row.original.id)}
              m={2}
              outline="0px"
              variant="outline"
              color={palette.ORANGE_OBI}
              border="1px solid"
              borderColor={palette.ORANGE_OBI}
              borderRadius="6px"
              aria-label="Open Edit"
              maxW={100}
              icon={<AiOutlineEdit />}
            />
            <IconButton
              m={2}
              outline="0px"
              variant="outline"
              color={palette.POMEGRANATE}
              border="1px solid"
              borderColor={palette.POMEGRANATE}
              borderRadius="6px"
              aria-label="Delete"
              maxW={100}
              icon={<AiFillDelete />}
              onClick={() => onRemoveEpisode(row.original.id)}
            />
          </>
        ),
      },
    ],
    [onRemoveEpisode, goToEditEpisode],
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = useTable(
    {
      columns,
      data: program?.episodes,
      initialState: { pageIndex: 0 },
    },
    usePagination,
  );

  return (
    <>
      <Flex justifyContent="flex-end">
        <Button
          margin="20px 0px"
          borderColor={palette.GREEN_LIGHT_OBI}
          size="primaryOutlineSmall"
          width="fit-content"
          color={palette.GREEN_LIGHT_OBI}
          onClick={goToCreateEpisode}
          variant="positiveOutline"
          leftIcon={<IoIosAdd />}
        >
          {t('Create')}
        </Button>
      </Flex>
      <Table w="100%" {...getTableProps()} className="stripedTable">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr _hover={{ cursor: 'pointer', bg: 'lightGrey' }} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export default ProgramEpisodesList;
