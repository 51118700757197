import { Flex, Heading, Input, Button, useToast } from '@chakra-ui/react';
import * as React from 'react';
import { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { palette } from 'theme/theme';
import { validateEmail } from 'utils';

import WBCustomAlert from 'components/WBAlert';
import { useAuth } from 'auth/hooks/useAuth';

interface IData {
  email: string;
  password: string;
  secureText: boolean;
  isValidEmail: boolean;
}

const LoginPage: FC = () => {
  const { login } = useAuth();
  const { t } = useTranslation();
  const toast = useToast();

  const [data, setData] = useState<IData>({
    email: '',
    password: '',
    secureText: true,
    isValidEmail: true,
  });

  const isFormValid = data.email && data.password && validateEmail(data.email);

  const controlForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );
  const isValidate = validateEmail(data.email);

  const checkValidityOnBlur = useCallback(() => {
    setData({ ...data, isValidEmail: isValidate });
  }, [data, isValidate]);

  const logIn = useCallback(async () => {
    setData({ ...data, isValidEmail: isValidate });
    if (isValidate) {
      const objLog = {
        email: data.email,
        password: data.password,
      };
      try {
        // dispatch(getLoginRequest(objLog));
        await login(objLog.email, objLog.password);
      } catch (error) {
        toast({
          description: typeof error === 'string' ? error : 'Wrong credentials',
          status: 'error',
          position: 'top',
          isClosable: true,
          duration: 10000,
        });
      }
    }
  }, [data, isValidate, login, toast]);

  return (
    <Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
      <Flex direction="column" backgroundColor={palette.WHITE} p={12} rounded={6} width="100%" maxWidth="400px">
        <Heading mb={6}>Login</Heading>
        <Input
          onBlur={checkValidityOnBlur}
          type="email"
          name="email"
          value={data.email}
          onChange={controlForm}
          placeholder={t('common:email')}
          outline="0px"
          mb={3}
        />
        {!data.isValidEmail ? <WBCustomAlert message="mailNotValid" status="error" /> : null}
        <Input
          type="password"
          name="password"
          value={data.password}
          onChange={controlForm}
          placeholder={t('common:password')}
          outline="0px"
          mb={3}
        />
        <Button
          onClick={logIn}
          variant={isFormValid ? 'secondaryOutline' : 'disabled'}
          size="primaryOutlineSmall"
          isDisabled={!isFormValid}
        >
          {t('common:Login')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default LoginPage;
