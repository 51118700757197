import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { messageHandlerSet } from 'redux/messageHandler/actions';
import {
  getAllNotificationListRequest,
  getAllNotificationListSuccess,
  getAllNotificationListFailed,
  postNewNotificationFailed,
  postNewNotificationSuccess,
  postNewNotificationRequest,
  postNewNotificationByIdRequest,
  postNewNotificationByIdFailed,
  postNewNotificationByIdSuccess,
  getNotificationSuccess,
  getNotificationFailed,
  getNotificationRequest,
  editNotificationSuccess,
  editNotificationFailed,
  editNotificationRequest,
  deleteNotificationSuccess,
  deleteNotificationFailed,
  deleteNotificationRequest,
  filterNotificationSuccess,
  filterNotificationFailed,
  filterNotificationRequest,
} from './actions';
import * as NotificationAPI from './apiCall';
import {
  deleteNotificationRequestPayload,
  editNotificationPayload,
  filterNotificationRequestPayload,
  getAllNotificationListRequestPayload,
  getNotificationRequestPayload,
  postNewNotificationByIdPayload,
  postNewNotificationPayload,
} from './types';
import i18n from '../../i18n';

function* getAlNotificationListSaga({ payload }: PayloadAction<getAllNotificationListRequestPayload>) {
  const { limit, page } = payload;

  try {
    const programListRes = yield call(NotificationAPI.getAllNotificationList, { limit, page });
    yield put(getAllNotificationListSuccess(programListRes));
  } catch (err: any) {
    yield put(getAllNotificationListFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noNotificationListFound') }),
    );
  }
}

function* postNotificationSaga({ payload }: PayloadAction<postNewNotificationPayload>) {
  try {
    yield call(NotificationAPI.addNewNotification, { ...payload });
    yield put(postNewNotificationSuccess());
    yield put(getAllNotificationListRequest({ limit: 10 }));
  } catch (err: any) {
    yield put(postNewNotificationFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithCreateNotification') }),
    );
  }
}

function* editNotificationSaga({ payload }: PayloadAction<editNotificationPayload>) {
  try {
    yield call(NotificationAPI.editNotification, { ...payload });
    yield put(editNotificationSuccess());
    yield put(getAllNotificationListRequest({ limit: 10 }));
  } catch (err: any) {
    yield put(editNotificationFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithCreateNotification') }),
    );
  }
}

function* postNotificationByIdSaga({ payload }: PayloadAction<postNewNotificationByIdPayload>) {
  try {
    yield call(NotificationAPI.addNewNotificationById, { ...payload });
    yield put(postNewNotificationByIdSuccess());
    yield put(getAllNotificationListRequest({ limit: 10 }));
  } catch (err: any) {
    yield put(postNewNotificationByIdFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithCreateNotification') }),
    );
  }
}

function* getNotificationSaga({ payload }: PayloadAction<getNotificationRequestPayload>) {
  try {
    const programListRes = yield call(NotificationAPI.getNotification, { ...payload });
    yield put(getNotificationSuccess(programListRes));
  } catch (err: any) {
    yield put(getNotificationFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noNotificationFound') }));
  }
}

function* deleteNotificationSaga({ payload }: PayloadAction<deleteNotificationRequestPayload>) {
  try {
    yield call(NotificationAPI.deleteNotification, { ...payload });
    yield put(deleteNotificationSuccess());
    yield put(getAllNotificationListRequest({ limit: 10 }));
  } catch (err: any) {
    yield put(deleteNotificationFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemDeletePlacement') }),
    );
  }
}

function* filterNotificationSaga({ payload }: PayloadAction<filterNotificationRequestPayload>) {
  try {
    const programListRes = yield call(NotificationAPI.getFilteredNotification, { ...payload });
    yield put(filterNotificationSuccess(programListRes));
  } catch (err: any) {
    yield put(filterNotificationFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithFilterNotification') }),
    );
  }
}
function* NotificationSaga() {
  yield takeLatest(getAllNotificationListRequest.type, getAlNotificationListSaga);
  yield takeLatest(postNewNotificationRequest.type, postNotificationSaga);
  yield takeLatest(postNewNotificationByIdRequest.type, postNotificationByIdSaga);
  yield takeLatest(getNotificationRequest.type, getNotificationSaga);
  yield takeLatest(editNotificationRequest.type, editNotificationSaga);
  yield takeLatest(deleteNotificationRequest.type, deleteNotificationSaga);
  yield takeLatest(filterNotificationRequest.type, filterNotificationSaga);
}

export default NotificationSaga;
