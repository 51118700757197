import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { palette } from 'theme/theme';
import { AiFillDelete, AiFillEye } from 'react-icons/ai';
import { Flex, IconButton, Button, Heading, Checkbox } from '@chakra-ui/react';
import { history } from 'routes/history';
import { useTranslation } from 'react-i18next';
import WBContainer from 'components/WBContainer';
import { AppRoutes } from 'routes/routesList';
import PUTable from 'components/WBTable';
import { deleteProductRequest, getAllProductListRequest } from 'redux/ProductList/actions';
import {
  allProductList,
  allProductListCurrentPage,
  allProductListElement,
  allProductListLoading,
  deleteProductLoading,
  loadingAddProduct,
  loadingProductDetails,
} from 'redux/ProductList/selectors';
import WBLoader from 'components/WBLoader';

const ProductList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const productList = useSelector(allProductList);
  const loadingProductList = useSelector(allProductListLoading);
  const loadingProductDetail = useSelector(loadingProductDetails);
  const AddProductLoading = useSelector(loadingAddProduct);
  const loadingAfterDeleting = useSelector(deleteProductLoading);
  const totalElements = useSelector(allProductListElement);
  const currentPage = useSelector(allProductListCurrentPage);
  const fetchIdRef = useRef(0);

  const createProduct = useCallback(() => {
    history.push(AppRoutes.AddProductPage);
  }, []);

  const goTo = useCallback((e: React.MouseEvent, value) => {
    e.stopPropagation();
    history.push(`${AppRoutes.ProductList}/${Number(value.original.id)}/edit`);
  }, []);

  const onDeleteProduct = useCallback(
    (id) => {
      dispatch(deleteProductRequest({ id }));
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Brand',
        accessor: 'brand',
      },
      {
        Header: 'Subcategory',
        accessor: 'subcategory',
      },
      {
        Header: 'Trend',
        accessor: 'trend',
        Cell: ({ row }) => (
          <Flex>
            {row.original.trend && (
              <Checkbox mb={10} minW="max-content" size="lg" colorScheme="orange" isChecked={true} disabled={true} />
            )}
          </Flex>
        ),
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          // cell.row.original.id
          <Flex>
            <IconButton
              onClick={(e) => goTo(e, row)}
              m={2}
              outline="0px"
              variant="outline"
              color={palette.BLUE_HIGHLIGHT_OBI}
              border="1px solid"
              borderColor={palette.BLUE_HIGHLIGHT_OBI}
              borderRadius="6px"
              aria-label="Edit email"
              maxW={100}
              icon={<AiFillEye />}
            />
            <IconButton
              m={2}
              outline="0px"
              variant="outline"
              color={palette.POMEGRANATE}
              border="1px solid"
              borderColor={palette.POMEGRANATE}
              borderRadius="6px"
              aria-label="delete"
              maxW={100}
              icon={<AiFillDelete />}
              onClick={() => onDeleteProduct(row?.original?.id)}
            />
          </Flex>
        ),
      },
    ],
    [goTo, onDeleteProduct],
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      if (fetchId === fetchIdRef.current) {
        dispatch(
          getAllProductListRequest({
            limit: pageSize,
            page: pageIndex + 1,
          }),
        );
      }
    },
    [dispatch],
  );

  if (loadingProductDetail || AddProductLoading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        backgroundColor="shadowGreyCard4"
      >
        <WBLoader />
      </Flex>
    );
  }

  return (
    <WBContainer>
      <Flex justifyContent="space-between">
        <Heading mb={6}>{t('ProductList')}</Heading>
        <Button onClick={createProduct} variant="secondaryOutline" size="primaryOutlineSmall" w={100}>
          {t('Create')}
        </Button>
      </Flex>
      <PUTable
        columns={columns}
        data={productList}
        loading={loadingProductList || loadingAfterDeleting}
        totalPages={totalElements}
        currentPage={currentPage}
        fetchData={fetchData}
        className="stripedTable"
      />
    </WBContainer>
  );
};

export default ProductList;
