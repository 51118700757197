import { Box, Image, Flex, Text, useDisclosure, IconButton } from '@chakra-ui/react';
import WBLoader from 'components/WBLoader';
import React, { FC, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { programDetail, programDetailLoading } from 'redux/ProgramDetail/selectors';
import { palette } from 'theme/theme';
import { AiOutlineEdit } from 'react-icons/ai';
import WBModalPrograms from 'components/WBModalPrograms';
import { isEmpty } from 'lodash';

const ProgramDetailPage: FC = () => {
  const { t } = useTranslation();

  const program = useSelector(programDetail);
  const programLoading = useSelector(programDetailLoading);

  const [rowEditable, setRowEditable] = useState(program);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onOpenModal = useCallback(() => {
    onOpen();

    if (!isEmpty(rowEditable) && rowEditable) {
      setRowEditable({ ...rowEditable });
    }
  }, [onOpen, rowEditable]);

  if (programLoading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        backgroundColor="shadowGreyCard4"
      >
        <WBLoader />
      </Flex>
    );
  }

  return (
    <>
      <Flex justifyContent="flex-end">
        <IconButton
          onClick={onOpenModal}
          m={2}
          outline="0px"
          variant="outline"
          color={palette.ORANGE_OBI}
          border="1px solid"
          borderColor={palette.ORANGE_OBI}
          borderRadius="6px"
          aria-label="Open Edit"
          maxW={100}
          icon={<AiOutlineEdit />}
        />
      </Flex>
      <WBModalPrograms
        type="programs"
        id={program?.id}
        rows={program}
        modalTitle={t('EditProgram')}
        onClose={onClose}
        isOpen={isOpen}
      />
      <Flex mt={10} w="100%" backgroundColor="white" height="fit-content">
        <Box width="sm">
          <Image
            height="100px"
            src={`data:image/png;base64,${program?.program_icon}`}
            alt={`${program?.broadcast} icon`}
          />
        </Box>
        <Flex m={0} ml={5} direction="column" w="20%" backgroundColor="white">
          <Flex minH="10px" alignItems="center">
            <Text fontWeight="bold" fontSize="2xl">
              {t('common:Title')}:
            </Text>
          </Flex>
          <Flex minH="10px" alignItems="center">
            <Text fontWeight="bold" fontSize="2xl">
              {t('common:BroadCaster')}:
            </Text>
          </Flex>
          <Flex minH="10px" alignItems="center">
            <Text fontWeight="bold" fontSize="2xl">
              {t('common:Description')}:
            </Text>
          </Flex>
        </Flex>

        <Flex m={0} ml={5} direction="column" w="100%" backgroundColor="white">
          <Flex pt="5px" minH="10px" alignItems="center">
            <Text ml={3} fontSize="xl">
              {program?.name ?? '--'}
            </Text>
          </Flex>
          <Flex pt="5px" minH="10px" alignItems="center">
            <Text ml={3} fontSize="xl">
              {program?.broadcast ?? '--'}
            </Text>
          </Flex>
          <Flex pt="5px" minH="10px" alignItems="center">
            <Text ml={3} fontSize="xl">
              {program?.details ?? '--'}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ProgramDetailPage;
