import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box, Flex, Divider } from '@chakra-ui/react';
import { palette } from 'theme/theme';

interface IMenuItems {
  children: ReactNode;
}

const MenuItems: FC<IMenuItems> = ({ children }) => (
  <Box textAlign="center" color={palette.WET_ASPHALT} fontSize="lg" lineHeight="22px" minW="130px" width="100%">
    {children}
  </Box>
);

const WBDrawer: FC = () => {
  const { t } = useTranslation();

  return (
    <Flex
      height="100vh"
      as="nav"
      align="center"
      justify="center"
      wrap="wrap"
      pt="10px"
      bg={palette.WHITE}
      w="250px"
      position="fixed"
      shadow="baseShadow"
      marginTop="66px"
      display="block"
    >
      <Box display="flex" alignSelf="center" justifyContent="center" flexGrow={1} flexDirection="column">
        <MenuItems>
          <NavLink
            to="/programs"
            activeClassName="navbar__link--active navbar__link--active_orange"
            className="navbar__link"
          >
            {t('common:ProgramList')}
          </NavLink>
        </MenuItems>

        <Divider />

        <MenuItems>
          <NavLink
            to="/products"
            activeClassName="navbar__link--active navbar__link--active_orange"
            className="navbar__link"
          >
            {t('common:ProductList')}
          </NavLink>
        </MenuItems>

        <Divider />

        <MenuItems>
          <NavLink
            to="/notifications"
            activeClassName="navbar__link--active navbar__link--active_orange"
            className="navbar__link"
          >
            {t('common:Notification')}
          </NavLink>
        </MenuItems>
        <Divider />
      </Box>
    </Flex>
  );
};

export default WBDrawer;
