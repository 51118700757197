import { createReducer } from '@reduxjs/toolkit';
import { getProductsListSuccessPayload } from 'redux/ProgramDetail/types';
import {
  getAllProductListRequest,
  getAllProductListSuccess,
  getAllProductListFailed,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailed,
  getProductDetailRequest,
  getProductDetailSuccess,
  getProductDetailFailed,
  postAddProductRequest,
  postAddProductSuccess,
  postAddProductFailed,
  patchProductRequest,
  patchProductSuccess,
  patchProductFailed,
  getBrandListRequest,
  getBrandListSuccess,
  getBrandListFailed,
  getSubCategoryListRequest,
  getSubCategoryListSuccess,
  getSubCategoryListFailed,
} from './actions';
import { getProductDetailSuccessPayload, IBrand, ISubcategory } from './types';

export interface IProductState {
  loadingDeleteProduct: boolean;
  loadingProductDetail: boolean;
  loadingAddProduct: boolean;
  loading: boolean;
  loadingBrands: boolean;
  loadingSubCategoryList: boolean;
  total_elements: number;
  currentPage: number;
  productList: getProductsListSuccessPayload[];
  productDetail: getProductDetailSuccessPayload;
  brandList: IBrand[];
  subCategoryList: ISubcategory[];
}

const initialState: IProductState = {
  loadingDeleteProduct: false,
  loadingProductDetail: false,
  loadingAddProduct: false,
  loading: false,
  loadingBrands: false,
  loadingSubCategoryList: false,
  total_elements: 0,
  currentPage: 1,
  productList: [],
  productDetail: {
    brand_id: 0,
    details: '',
    episode: [],
    episode_start_at: new Date(),
    icon_id: '',
    icon_url: '',
    id: 0,
    name: '',
    owner: 0,
    product_url: '',
    program: '',
    subcategory: {
      id: 0,
      category_id: 0,
      name: '',
    },
    trend: false,
    whish_list: false,
  },
  brandList: [],
  subCategoryList: [],
};

export const allProductReducer = createReducer(initialState, {
  [getAllProductListRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllProductListSuccess.type]: (state, action) => {
    state.loading = false;
    state.total_elements = action.payload.total_elements;
    state.currentPage = action.payload.currentPage;
    state.productList = action.payload.elements;
  },
  [getAllProductListFailed.type]: (state) => {
    state.loading = false;
  },
  [deleteProductRequest.type]: (state) => {
    state.loadingDeleteProduct = true;
  },
  [deleteProductSuccess.type]: (state) => {
    state.loadingDeleteProduct = false;
  },
  [deleteProductFailed.type]: (state) => {
    state.loadingDeleteProduct = false;
  },
  [getProductDetailRequest.type]: (state) => {
    state.loadingProductDetail = true;
  },
  [getProductDetailSuccess.type]: (state, action) => {
    state.loadingProductDetail = false;
    state.productDetail = action.payload;
  },
  [getProductDetailFailed.type]: (state) => {
    state.loadingProductDetail = false;
  },
  [postAddProductRequest.type]: (state) => {
    state.loadingAddProduct = true;
  },
  [postAddProductSuccess.type]: (state) => {
    state.loadingAddProduct = false;
  },
  [postAddProductFailed.type]: (state) => {
    state.loadingAddProduct = false;
  },
  [patchProductRequest.type]: (state) => {
    state.loading = true;
  },
  [patchProductSuccess.type]: (state) => {
    state.loading = false;
  },
  [patchProductFailed.type]: (state) => {
    state.loading = false;
  },
  [getBrandListRequest.type]: (state) => {
    state.loadingBrands = true;
  },
  [getBrandListSuccess.type]: (state, action) => {
    state.loadingBrands = false;
    state.brandList = action.payload.brands;
  },
  [getBrandListFailed.type]: (state) => {
    state.loadingBrands = false;
  },
  [getSubCategoryListRequest.type]: (state) => {
    state.loadingBrands = true;
  },
  [getSubCategoryListSuccess.type]: (state, action) => {
    state.loadingBrands = false;
    state.subCategoryList = action.payload;
  },
  [getSubCategoryListFailed.type]: (state) => {
    state.loadingBrands = false;
  },
});
