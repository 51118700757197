import { createReducer } from '@reduxjs/toolkit';
import {
  getAllNotificationListRequest,
  getAllNotificationListSuccess,
  getAllNotificationListFailed,
  postNewNotificationRequest,
  postNewNotificationFailed,
  postNewNotificationSuccess,
  postNewNotificationByIdRequest,
  postNewNotificationByIdSuccess,
  postNewNotificationByIdFailed,
  getNotificationRequest,
  getNotificationSuccess,
  getNotificationFailed,
  editNotificationRequest,
  editNotificationSuccess,
  editNotificationFailed,
  filterNotificationRequest,
  filterNotificationSuccess,
  filterNotificationFailed,
} from './actions';
import { getNotificationSuccessPayload } from './types';

export type Product = {
  name: string;
  details: string;
  product_url: string;
  product_icon: string;
};

export type SingleElement = {
  id: number;
  title: string;
  description: string;
  type: string;
  sent_at: string;
  is_read: boolean;
  product: Product;
};

export interface INotificationState {
  loading: boolean;
  loadingNotification: boolean;
  loadingPostNotification: boolean;
  loadingPostNotificationById: boolean;
  loadingEditNotification: boolean;
  loadingFilterNotification: boolean;
  notification: getNotificationSuccessPayload;
  notifies: {
    total_elements: number;
    elements: SingleElement[];
    currentPage: number;
  };
}

const initialState: INotificationState = {
  loading: false,
  loadingNotification: false,
  loadingPostNotification: false,
  loadingPostNotificationById: false,
  loadingEditNotification: false,
  loadingFilterNotification: false,
  notification: {
    description: '',
    episode_id: 0,
    episode_name: '',
    product_icon_url: '',
    product_placement_id: 0,
    product_url: '',
    program_id: 0,
    program_name: '',
    send_at: '',
    status: 0,
    title: '',
    type: '',
    type_id: 0,
    brand: '',
  },
  notifies: {
    total_elements: 0,
    currentPage: 1,
    elements: [],
  },
};

export const notificationReducer = createReducer(initialState, {
  [getAllNotificationListRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllNotificationListSuccess.type]: (state, action) => {
    state.loading = false;
    state.notifies.total_elements = action.payload.total_elements;
    state.notifies.currentPage = action.payload.currentPage;
    state.notifies.elements = action.payload.elements;
  },
  [getAllNotificationListFailed.type]: (state) => {
    state.loading = false;
  },
  [postNewNotificationRequest.type]: (state) => {
    state.loadingPostNotification = true;
  },
  [postNewNotificationSuccess.type]: (state) => {
    state.loadingPostNotification = false;
  },
  [postNewNotificationFailed.type]: (state) => {
    state.loadingPostNotification = false;
  },
  [editNotificationRequest.type]: (state) => {
    state.loadingEditNotification = true;
  },
  [editNotificationSuccess.type]: (state) => {
    state.loadingEditNotification = false;
  },
  [editNotificationFailed.type]: (state) => {
    state.loadingEditNotification = false;
  },
  [postNewNotificationByIdRequest.type]: (state) => {
    state.loadingPostNotificationById = true;
  },
  [postNewNotificationByIdSuccess.type]: (state) => {
    state.loadingPostNotificationById = false;
  },
  [postNewNotificationByIdFailed.type]: (state) => {
    state.loadingPostNotificationById = false;
  },
  [getNotificationRequest.type]: (state) => {
    state.loadingNotification = true;
  },
  [getNotificationSuccess.type]: (state, action) => {
    state.loadingNotification = false;
    state.notification = action.payload;
  },
  [getNotificationFailed.type]: (state) => {
    state.loadingNotification = false;
  },
  [filterNotificationRequest.type]: (state) => {
    state.loadingFilterNotification = true;
  },
  [filterNotificationSuccess.type]: (state, action) => {
    state.loadingFilterNotification = false;
    state.notifies.total_elements = action.payload.total_elements;
    state.notifies.currentPage = action.payload.currentPage;
    state.notifies.elements = action.payload.elements;
  },
  [filterNotificationFailed.type]: (state) => {
    state.loadingFilterNotification = false;
  },
});
