import React, { FC, useCallback, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { palette } from 'theme/theme';
import { AiFillDelete, AiFillEye } from 'react-icons/ai';
import { Flex, IconButton, Button, Heading } from '@chakra-ui/react';
import {
  allProgramList,
  allProgramListCurrentPage,
  allProgramListLoading,
  allProgramListTotalElements,
  deleteProgramLoading,
} from 'redux/ProgramList/selectors';
import { deleteProgramRequest, getAllProgramListRequest } from 'redux/ProgramList/actions';
import { history } from 'routes/history';
import { useTranslation } from 'react-i18next';
import WBContainer from 'components/WBContainer';
import { AppRoutes } from 'routes/routesList';
import PUTable from 'components/WBTable';

const ProgramList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const programList = useSelector(allProgramList);
  const loadingProgramList = useSelector(allProgramListLoading);
  const loadingAfterDeleting = useSelector(deleteProgramLoading);
  const totalElements = useSelector(allProgramListTotalElements);
  const currentPage = useSelector(allProgramListCurrentPage);
  const fetchIdRef = useRef(0);

  const createProgram = useCallback(() => {
    history.push(AppRoutes.AddProgramPage);
  }, []);

  const goTo = useCallback((e: React.MouseEvent, value) => {
    e.stopPropagation();
    history.push(`${AppRoutes.ProgramList}/${Number(value.original.program_id)}/edit`);
  }, []);

  const onDeleteProgram = useCallback(
    (id) => {
      dispatch(deleteProgramRequest({ id }));
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Program name',
        accessor: 'program_name',
      },
      {
        Header: 'Broadcaster',
        accessor: 'broadcast',
      },

      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          // cell.row.original.id
          <Flex>
            <IconButton
              onClick={(e) => goTo(e, row)}
              m={2}
              outline="0px"
              variant="outline"
              color={palette.BLUE_HIGHLIGHT_OBI}
              border="1px solid"
              borderColor={palette.BLUE_HIGHLIGHT_OBI}
              borderRadius="6px"
              aria-label="Edit email"
              maxW={100}
              icon={<AiFillEye />}
            />
            <IconButton
              m={2}
              outline="0px"
              variant="outline"
              color={palette.POMEGRANATE}
              border="1px solid"
              borderColor={palette.POMEGRANATE}
              borderRadius="6px"
              aria-label="delete"
              maxW={100}
              icon={<AiFillDelete />}
              onClick={() => onDeleteProgram(row?.original?.program_id)}
            />
          </Flex>
        ),
      },
    ],
    [goTo, onDeleteProgram],
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      if (fetchId === fetchIdRef.current) {
        dispatch(
          getAllProgramListRequest({
            limit: pageSize,
            page: pageIndex + 1,
          }),
        );
      }
    },
    [dispatch],
  );

  return (
    <WBContainer>
      <Flex justifyContent="space-between">
        <Heading mb={6}>{t('ProgramList')}</Heading>
        <Button onClick={createProgram} variant="secondaryOutline" size="primaryOutlineSmall" w={100}>
          {t('Create')}
        </Button>
      </Flex>

      <PUTable
        columns={columns}
        data={programList || []}
        loading={loadingProgramList || loadingAfterDeleting}
        totalPages={totalElements}
        currentPage={currentPage}
        fetchData={fetchData}
        className="stripedTable"
      />

      {/* <Table w="100%" {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr _hover={{ cursor: 'pointer', bg: 'lightGrey' }} {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render('Cell')}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex>
          <Tooltip label="First Page">
            <IconButton
              aria-label="gotToFirstPage"
              onClick={gotoPageCustom}
              isDisabled={!canPreviousPage}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Previous Page">
            <IconButton
              aria-label="gotToPreviewsPage"
              onClick={previousPage}
              isDisabled={!canPreviousPage}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>

        <Flex alignItems="center">
          <Text mr={8}>
            {t('common:Page')}
            <Text fontWeight="bold" as="span">
              {pageIndex + 1}
            </Text>
            {t('common:Of')}
            <Text fontWeight="bold" as="span">
              {pageOptions.length}
            </Text>
          </Text>
          <Text>{t('common:Gotopage')}:</Text>
          <NumberInput
            ml={2}
            mr={8}
            w={28}
            min={1}
            max={pageOptions.length}
            onChange={(e) => gotoPageCustom(e)}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Select
            w={32}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t('common:Show')} {pageSize}
              </option>
            ))}
          </Select>
        </Flex>

        <Flex>
          <Tooltip label="Next Page">
            <IconButton
              aria-label="gotoNextPage"
              onClick={nextPage}
              isDisabled={!canNextPage}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Last Page">
            <IconButton
              aria-label="gotToLastPage"
              onClick={() => gotoPageCustom(pageCount - 1)}
              isDisabled={!canNextPage}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex> */}
    </WBContainer>
  );
};

export default ProgramList;
