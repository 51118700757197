import React, { FC, useCallback, useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Input,
  Textarea,
  Image,
  Box,
  Checkbox,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { patchProgramRequest } from 'redux/ProgramDetail/actions';
import Previews from 'components/WBDropzonePreview';
import { computeChecksumMd5 } from 'utils';
import { isEmpty } from 'lodash';
import WBSelect from 'components/WBSelect';
import { getBroadCastSuccessListPayload, ISelect } from 'redux/ProgramDetail/types';
import { broadCastList } from 'redux/ProgramDetail/selectors';

interface IWBModal {
  onClose: () => void;
  isOpen: boolean;
  modalTitle: string;
  id?: number;
  type: string;
  rows?: {
    id?: number;
    name?: string;
    details?: string;
    broadcast?: string;
    program_icon?: string;
    program_icon_id?: string;
    broadcast_id?: number;
  };
}
const initialStateSelects: ISelect = {
  value: 0,
  label: '',
};

/* WIP: create one WBModal? with WBModalEditEpisode */
const WBModalPrograms: FC<IWBModal> = ({ onClose, isOpen, modalTitle, rows, id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [data, setData] = useState<any>(rows);
  const [files, setFiles] = useState<any>([]);
  const [selectedBroadCast, setSelectedBroadCast] = useState<ISelect>(initialStateSelects);

  const broadCasts = useSelector(broadCastList);

  /**
   * validation form
   * @return {Boolean}
   */
  const isFormValid: boolean = !!(
    data?.name &&
    data?.details &&
    data?.broadcast &&
    data?.program_icon &&
    (data?.program_icon_url || !isEmpty(files))
  );

  const onSetFile = useCallback(async (file) => {
    const blob = new Blob(file, { type: 'text/plain' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    const hash = await computeChecksumMd5(file);
    reader.onloadend = function () {
      const base64String = reader?.result?.toString().split(',')[1];
      setFiles({ file, base64String, hash });
    };
  }, []);

  const onRemoveFile = useCallback(() => {
    setFiles([]);
  }, []);

  const sendEditImage = useCallback(() => {
    if (!isEmpty(files.file)) {
      return {
        files: [
          {
            filename: !isEmpty(!files.file) && files?.file[0]?.path,
            hash: files?.hash,
            filestream: files?.base64String,
          },
        ],
      };
    } else {
      return null;
    }
  }, [files?.base64String, files.file, files?.hash]);

  const onHandleHighlight = useCallback(
    (e) => {
      setData({ ...data, highlight: e.target.checked });
    },
    [data],
  );

  const sendEditProgram = useCallback(() => {
    if (isFormValid) {
      const dataObj = {
        id: id,
        name: data?.name,
        highlight: data?.highlight,
        details: data?.details,
        broadcast_id: selectedBroadCast.value,
        icon: sendEditImage(),
      };
      dispatch(patchProgramRequest(dataObj));
    }
    onClose();
  }, [
    data?.details,
    data?.highlight,
    data?.name,
    dispatch,
    id,
    isFormValid,
    onClose,
    selectedBroadCast.value,
    sendEditImage,
  ]);

  const controlForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const onSelectBroadCast = useCallback((selectedBroadCast) => {
    setSelectedBroadCast(selectedBroadCast);
  }, []);

  const onFactorySelect = useCallback((list) => {
    return !isEmpty(list)
      ? list.map((l) => {
          return {
            value: l?.id,
            label: l?.name,
          };
        })
      : [];
  }, []);

  useEffect(() => {
    if (id === rows?.id) {
      setData(rows);
    }
  }, [rows, id]);

  useEffect(() => {
    const findRightBroadCast: getBroadCastSuccessListPayload = broadCasts.find(
      (l: getBroadCastSuccessListPayload) => l.id === Number(rows?.broadcast_id),
    ) as unknown as getBroadCastSuccessListPayload;
    const refactorBroadCast = {
      value: findRightBroadCast?.id,
      label: findRightBroadCast?.name,
    };
    setSelectedBroadCast(refactorBroadCast);
  }, [broadCasts, onSelectBroadCast, rows?.broadcast_id]);

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex w="100%" direction="column" backgroundColor="white">
            <Flex direction="column" mb={50}>
              <Text fontSize="xl">{t('common:Title')}*</Text>
              <Input name="name" value={data?.name} onChange={controlForm} outline="0px" mb={3} />

              <Text fontSize="xl">{t('common:isHighlight')}</Text>
              <Checkbox
                mb={10}
                onChange={onHandleHighlight}
                minW="max-content"
                size="lg"
                colorScheme="orange"
                name="highlight"
                isChecked={data?.highlight}
              />

              <Text fontSize="xl">{t('common:Description')}*</Text>
              <Textarea
                minH={200}
                name="details"
                value={data?.details}
                onChange={controlForm}
                mb={5}
                alignItems="center"
                outline="0px"
              />

              <Text fontSize="xl">{t('common:BroadCast')}*</Text>
              <Box mb={5}>
                <WBSelect
                  isMulti={false}
                  name="broadcast_id"
                  options={onFactorySelect(broadCasts)}
                  onChange={onSelectBroadCast}
                  value={selectedBroadCast}
                />
              </Box>

              <Text fontSize="xl">{t('common:Image')}*</Text>
              <Previews setFile={onSetFile} files={files?.file} removeFile={onRemoveFile} />
              {data?.program_icon_url && isEmpty(files.file) && (
                <Flex alignSelf="center">
                  <Image src={data?.program_icon_url} width="200px" height="200px" />
                </Flex>
              )}
            </Flex>
          </Flex>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            {t('Close')}
          </Button>
          <Button
            onClick={sendEditProgram}
            variant={isFormValid ? 'secondaryOutline' : 'disabled'}
            size="primaryOutlineSmall"
            width="300px"
            isDisabled={!isFormValid}
          >
            {t('common:Save')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default WBModalPrograms;
