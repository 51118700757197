import { Flex, Heading, Input, Button, Textarea, Text, Box, Image, Checkbox } from '@chakra-ui/react';
import React, { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from 'routes/history';
import WBContainer from 'components/WBContainer';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { getBroadCastListRequest, getProgramIconRequest, postAddProgramRequest } from 'redux/ProgramDetail/actions';
import format from 'date-fns/format';
import WBSelect from 'components/WBSelect';
import { broadCastList } from 'redux/ProgramDetail/selectors';
import { ProgramIconEpisodeIconBroadCast } from 'redux/ProgramDetail/types';
import Previews from 'components/WBDropzonePreview';
import { isEmpty } from 'lodash';
import { computeChecksumMd5 } from '../../utils/index';
import { addMonths } from 'date-fns';

interface IData {
  name: string;
  details: string;
  hightlight: boolean;
  broadcast_id: number;
  icon_id: number;
  video_url: string;
  episodes: [];
  start_at: Date | number;
  json?: string;
  file?: File;
}

const initialStateData: IData = {
  name: '',
  details: '',
  hightlight: false,
  broadcast_id: 0,
  icon_id: 0,
  video_url: '',
  episodes: [],
  start_at: 0,
  json: '',
  file: undefined,
};

interface ISelect {
  value: number;
  label: string;
}

const initialStateSelects: ISelect = {
  value: 0,
  label: '',
};

const AddProgramPage: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const broadCasts = useSelector(broadCastList);

  const [data, setData] = useState<any>(initialStateData);
  const [selectedBroadCast, setSelectedBroadCast] = useState<ISelect>(initialStateSelects);
  const [files, setFiles] = useState<any>([]);

  const isFormValid = !!(data?.name && data?.details && data.start_at && (data?.program_icon_url || !isEmpty(files)));

  const chooseDay = useCallback(
    (e) => {
      setData({ ...data, start_at: e });
    },
    [data],
  );

  const onHandleHighlight = useCallback(
    (e) => {
      setData({ ...data, highlight: e.target.checked });
    },
    [data],
  );

  const onSetFile = useCallback(async (file) => {
    const blob = new Blob(file, { type: 'text/plain' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    const hash = await computeChecksumMd5(file);
    reader.onloadend = function () {
      const base64String = reader?.result?.toString().split(',')[1];
      setFiles({ file, base64String, hash });
    };
  }, []);

  const controlForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const onSelectBroadCast = useCallback((selectedBroadCast) => {
    setSelectedBroadCast(selectedBroadCast.value);
  }, []);

  const onFactorySelect = useCallback((list: ProgramIconEpisodeIconBroadCast[]) => {
    return !isEmpty(list)
      ? list.map((l) => {
          return {
            value: l?.id,
            label: l?.name,
          };
        })
      : [];
  }, []);

  const onRemoveFile = useCallback(() => {
    setFiles([]);
  }, []);

  const sendEditImage = useCallback(() => {
    if (!isEmpty(files.file)) {
      return {
        files: [
          {
            filename: !isEmpty(!files.file) && files?.file[0]?.path,
            hash: files?.hash,
            filestream: files?.base64String,
          },
        ],
      };
    } else {
      return null;
    }
  }, [files?.base64String, files.file, files?.hash]);

  const sendProgram = useCallback(() => {
    const sendProgramObject = {
      name: data?.name,
      details: data?.details,
      highlight: data?.highlight,
      broadcast_id: selectedBroadCast,
      video_url: data?.video_url,
      start_at: format(data?.start_at, 'dd-MM-yyyy HH:mm') as unknown as Date,
      end_at: addMonths(new Date(data?.start_at), 4) as unknown as Date,
      episodes: data?.episodes,
      icon: sendEditImage(),
    };
    dispatch(postAddProgramRequest(sendProgramObject));
    history.push(`/programs`);
  }, [
    data?.details,
    data?.episodes,
    data?.highlight,
    data?.name,
    data?.start_at,
    data?.video_url,
    dispatch,
    selectedBroadCast,
    sendEditImage,
  ]);

  useEffect(() => {
    dispatch(getBroadCastListRequest());
    dispatch(getProgramIconRequest());
  }, [dispatch]);

  return (
    <WBContainer>
      <Heading display="block" mb={6}>
        {t('common:AddProgram')}
      </Heading>
      <Flex w="80%" direction="column" backgroundColor="white">
        <Flex direction="column" mb={50}>
          <Text fontSize="xl">{t('common:Title')}*</Text>
          <Input name="name" value={data.name} onChange={controlForm} outline="0px" mb={3} />

          <Text fontSize="xl">{t('common:isHighlight')}</Text>
          <Checkbox
            mb={10}
            onChange={onHandleHighlight}
            minW="max-content"
            size="lg"
            colorScheme="orange"
            name="highlight"
            isChecked={data?.highlight}
          />

          <Text fontSize="xl">{t('common:BroadCaster')}</Text>
          <Box mb={5}>
            <WBSelect
              isMulti={false}
              name="broadcast_id"
              options={onFactorySelect(broadCasts)}
              onChange={onSelectBroadCast}
              value={selectedBroadCast.value}
            />
          </Box>
          {/* <Text fontSize="xl">{t('common:Video')}</Text> */}
          {/* <Input name="video_url" value={data.video_url} onChange={controlForm} outline="0px" mb={3} /> */}
          <Text fontSize="xl">{t('common:Description')}*</Text>
          <Textarea
            minH={200}
            name="details"
            value={data.details}
            onChange={controlForm}
            mb={5}
            alignItems="center"
            outline="0px"
          />
          <Text fontSize="xl">{t('common:Image')}*</Text>
          <Previews setFile={onSetFile} files={files?.file} removeFile={onRemoveFile} />
          {data?.program_icon_url && isEmpty(files.file) && (
            <Flex alignSelf="center">
              <Image src={data?.program_icon_url} width="200px" height="200px" />
            </Flex>
          )}
          <Box mb={10} width="100%">
            <Text fontSize="xl">{t('common:Date')}*</Text>
            <DateTimePicker onChange={chooseDay} value={data?.start_at} />
          </Box>
          <Flex justifyContent="flex-end" w="100%" backgroundColor="white" rounded={6}>
            <Button
              onClick={sendProgram}
              variant={isFormValid ? 'secondaryOutline' : 'disabled'}
              size="primaryOutlineSmall"
              width="300px"
              isDisabled={!isFormValid}
            >
              {t('common:Save')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </WBContainer>
  );
};

export default AddProgramPage;
