import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { messageHandlerSet } from 'redux/messageHandler/actions';
import {
  getAllProgramListRequest,
  getAllProgramListSuccess,
  getAllProgramListFailed,
  deleteProgramSuccess,
  deleteProgramFailed,
  deleteProgramRequest,
} from './actions';
import * as FilmsAPI from './apiCall';
import { deleteProgramRequestPayload, getAllProgramListRequestPayload } from './types';
import i18n from '../../i18n';

function* getAllProgramListSaga({ payload }: PayloadAction<getAllProgramListRequestPayload>) {
  try {
    const programListRes = yield call(FilmsAPI.getAllProgramList, { ...payload });
    yield put(getAllProgramListSuccess(programListRes));
  } catch (err: any) {
    yield put(getAllProgramListFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noProgramListFound') }));
  }
}
function* deleteProgramSaga({ payload }: PayloadAction<deleteProgramRequestPayload>) {
  try {
    yield call(FilmsAPI.deleteProgram, { ...payload });
    yield put(deleteProgramSuccess());
    yield put(getAllProgramListRequest({ limit: 10 }));
  } catch (err: any) {
    yield put(deleteProgramFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithDeleteProgram') }),
    );
  }
}

function* programSaga() {
  yield takeLatest(getAllProgramListRequest.type, getAllProgramListSaga);
  yield takeLatest(deleteProgramRequest.type, deleteProgramSaga);
}

export default programSaga;
