import { createAction } from '@reduxjs/toolkit';
import {
  getProgramDetailSuccessPayload,
  getProgramDetailRequestPayload,
  postAddProgramRequestPayload,
  patchProgramRequestPayload,
  getProgramIconSuccessPayload,
  getBroadCastSuccessListPayload,
  getEpisodeIcoSuccessPayload,
  patchEpisodeRequestPayload,
  deleteEpisodeRequestPayload,
  addEpisodePayloadRequest,
  postAddTableJSONRequestPayload,
  getProductsListSuccessPayload,
  patchTableJSONRequestPayload,
  deleteTableJSONRequestPayload,
} from './types';

export const getProgramDetailRequest = createAction<getProgramDetailRequestPayload>('GET_PROGRAM_DETAIL_REQUEST');
export const getProgramcDetailSuccess = createAction<getProgramDetailSuccessPayload>('GET_PROGRAM_DETAIL_SUCCESS');
export const getProgramDetailFailed = createAction('GET_PROGRAM_DETAIL_FAILED');

export const postAddProgramRequest = createAction<postAddProgramRequestPayload>('POST_ADD_PROGRAM_REQUEST');
export const postAddProgramSuccess = createAction('POST_ADD_PROGRAM_SUCCESS');
export const postAddProgramFailed = createAction('POST_ADD_PROGRAM_FAILED');

export const patchProgramRequest = createAction<patchProgramRequestPayload>('PATCH_PROGRAM_REQUEST');
export const patchProgramSuccess = createAction('PATCH_PROGRAM_SUCCESS');
export const patchProgramFailed = createAction('PATCH_PROGRAM_FAILED');

export const getProgramIconRequest = createAction('GET_PROGRAM_ICON_REQUEST');
export const getProgramIconSuccess = createAction<getProgramIconSuccessPayload>('GET_PROGRAM_ICON_SUCCESS');
export const getProgramIconFailed = createAction('GET_PROGRAM_ICON_FAILED');

export const getEpisodeIconRequest = createAction('GET_EPISODE_ICON_REQUEST');
export const getEpisodeIconSuccess = createAction<getEpisodeIcoSuccessPayload>('GET_EPISODE_ICON_SUCCESS');
export const getEpisodeIconFailed = createAction('GET_EPISODE_ICON_FAILED');

export const getBroadCastListRequest = createAction('GET_BROADCAST_LIST_REQUEST');
export const getBroadCastListSuccess = createAction<getBroadCastSuccessListPayload>('GET_BROADCAST_LIST_SUCCESS');
export const getBroadCastListFailed = createAction('GET_BROADCAST_LIST_FAILED');

export const deleteEpisodeRequest = createAction<deleteEpisodeRequestPayload>('DELETE_EPISODE_REQUEST');
export const deleteEpisodeSuccess = createAction('DELETE_EPISODE_SUCCESS');
export const deleteEpisodeFailed = createAction('DELETE_EPISODE_FAILED');

export const patchEpisodeRequest = createAction<patchEpisodeRequestPayload>('PATCH_EPISODE_REQUEST');
export const patchEpisodeSuccess = createAction('PATCH_EPISODE_SUCCESS');
export const patchEpisodeFailed = createAction('PATCH_EPISODE_FAILED');

export const addEpisodeRequest = createAction<addEpisodePayloadRequest>('ADD_EPISODE_REQUEST');
export const addEpisodeSuccess = createAction('ADD_EPISODE_SUCCESS');
export const addEpisodeFailed = createAction('ADD_EPISODE_FAILED');

export const getProductListRequest = createAction('GET_PRODUCT_LIST_REQUEST');
export const getProductListSuccess = createAction<getProductsListSuccessPayload>('GET_PRODUCT_LIST_SUCCESS');
export const getProductListFailed = createAction('GET_PRODUCT_LIST_FAILED');

export const postAddTableJSONRequest = createAction<postAddTableJSONRequestPayload>('POST_ADD_TABLE_JSON_REQUEST');
export const postAddTableJSONSuccess = createAction('POST_ADD_TABLE_JSON_SUCCESS');
export const postAddTableJSONFailed = createAction('POST_ADD_TABLE_JSON_FAILED');

export const patchTableJSONRequest = createAction<patchTableJSONRequestPayload>('PATCH_TABLE_JSON_REQUEST');
export const patchTableJSONSuccess = createAction('PATCH_TABLE_JSON_SUCCESS');
export const patchTableJSONFailed = createAction('PATCH_TABLE_JSON_FAILED');

export const deleteTableJSONRequest = createAction<deleteTableJSONRequestPayload>('DELETE_TABLE_JSON_REQUEST');
export const deleteTableJSONSuccess = createAction('DELETE_TABLE_JSON_SUCCESS');
export const deleteTableJSONFailed = createAction('DELETE_TABLE_JSON_FAILED');

export const resetProgramData = createAction('RESET_PRRAMG_INFO');
