import axios from 'axios';
import { LOGIN_API_URL, REFRESH_TOKEN_API_URL } from './paths';

const APIClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const APIRefreshClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

type LoginOptions = {
  username: string;
  password: string;
};

// @TODO: if needed organizations modify this type
type TokenResponse = {
  accessToken?: string;
  refreshToken?: string;
  token?: string;
  refresh_token?: string;
};

type TokenResponseAPI = {
  token: string;
  refresh_token: string;
};

export async function login({ username, password }: LoginOptions): Promise<TokenResponse> {
  const { data } = await APIClient.post<TokenResponseAPI>(LOGIN_API_URL, {
    email: username,
    password,
  });

  return {
    // instance: ........
    accessToken: data.token,
    refreshToken: data.refresh_token,
  };
}

type RefreshTokenOptions = {
  refreshToken: string;
};

// eslint-disable-next-line no-shadow
export async function refreshToken({ refreshToken }: RefreshTokenOptions): Promise<TokenResponse> {
  try {
    const { data } = await APIRefreshClient.post<TokenResponse>(REFRESH_TOKEN_API_URL, {
      refresh_token: refreshToken,
    });

    return data;
  } catch (err) {
    console.error('refreshToken - Error: ', err);
  }

  return {
    accessToken: '',
    refreshToken: '',
  };
}
