import { ChakraProps, Flex } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';
import { palette } from 'theme/theme';

interface PUCardContainerProps {
  children: ReactNode;
  mb?: string;
  noShadow?: boolean;
  noBorders?: boolean;
}

const PUCardContainer: FC<PUCardContainerProps & ChakraProps> = ({
  children,
  mb = '0px',
  noShadow = false,
  noBorders = false,
  ...rest
}) => {
  return (
    <Flex
      border={noBorders ? 'none' : '1px'}
      borderColor="grey3"
      borderRadius="10px"
      padding="24px"
      mb={mb}
      flexDirection="column"
      boxShadow={noShadow ? 'transparent' : `0 0 5px 2px ${palette.SILVER}`}
      w="100%"
      {...rest}
    >
      {children}
    </Flex>
  );
};

export default PUCardContainer;
