import { createReducer } from '@reduxjs/toolkit';
import {
  getProgramDetailRequest,
  getProgramcDetailSuccess,
  getProgramDetailFailed,
  postAddProgramRequest,
  postAddProgramSuccess,
  patchProgramRequest,
  patchProgramFailed,
  patchProgramSuccess,
  postAddProgramFailed,
  getProgramIconRequest,
  getProgramIconSuccess,
  getProgramIconFailed,
  getEpisodeIconRequest,
  getEpisodeIconSuccess,
  getEpisodeIconFailed,
  getBroadCastListRequest,
  getBroadCastListSuccess,
  getBroadCastListFailed,
  patchEpisodeRequest,
  patchEpisodeSuccess,
  patchEpisodeFailed,
  addEpisodeRequest,
  addEpisodeSuccess,
  addEpisodeFailed,
  deleteEpisodeRequest,
  deleteEpisodeSuccess,
  deleteEpisodeFailed,
  getProductListRequest,
  getProductListSuccess,
  getProductListFailed,
  patchTableJSONRequest,
  patchTableJSONSuccess,
  patchTableJSONFailed,
  deleteTableJSONRequest,
  deleteTableJSONSuccess,
  deleteTableJSONFailed,
  resetProgramData,
} from './actions';

export type SingleEpisode = {
  id: number;
  name: string;
  placement: any; // TODO:
  description: string;
  preferred: boolean;
  start_at: string;
  end_at: string;
  file: File;
};

export interface IProgramsDetailState {
  loading: boolean;
  loadingEpisode: boolean;
  loadingEpisodeEdit: boolean;
  loadingBroadCastList: boolean;
  loadingProgramIconList: boolean;
  loadingEpisodeIconList: boolean;
  loadingDeleteEpisode: boolean;
  loadingProductList: boolean;
  loadingPatchTable: boolean;
  loadingDeleteTable: boolean;
  programIcon: [];
  episodeIcon: [];
  broadCastList: [];
  programDetail:
    | {
        id: number;
        name: string;
        details: string;
        program_icon: string;
        broadcast: string;
        broadCaster?: string;
        json?: string;
        start_at: string;
        file?: File;
        episodes: SingleEpisode[];
        program_icon_id: string;
        broadcast_id: number;
      }
    | undefined;
  productList: {
    elements: {
      id: number;
      name: string;
      details: string;
      product_url: string;
      subcategory: {
        id: number;
        category_id: number;
        name: string;
      };
      trend: boolean;
      whish_list: boolean;
      owner: string;
      program: string;
      episode: string;
      episode_start_at: string;
      icon_id: string;
      icon_url: string;
    }[];
  };
}

const initialState: IProgramsDetailState = {
  loading: false,
  loadingEpisode: false,
  loadingEpisodeEdit: false,
  loadingBroadCastList: false,
  loadingProgramIconList: false,
  loadingEpisodeIconList: false,
  loadingDeleteEpisode: false,
  loadingProductList: false,
  loadingPatchTable: false,
  loadingDeleteTable: false,
  programIcon: [],
  episodeIcon: [],
  broadCastList: [],

  programDetail: {
    id: 0,
    name: '',
    details: '',
    program_icon: '',
    broadcast: '',
    broadCaster: '',
    json: '',
    start_at: '',
    file: {} as File,
    episodes: [],
    program_icon_id: '',
    broadcast_id: 0,
  },
  productList: {
    elements: [
      {
        id: 0,
        name: '',
        details: '',
        product_url: '',
        subcategory: {
          id: 0,
          category_id: 0,
          name: '',
        },
        trend: false,
        whish_list: false,
        owner: '',
        program: '',
        episode: '',
        episode_start_at: '',
        icon_id: '',
        icon_url: '',
      },
    ],
  },
};

export const programDetailReducer = createReducer(initialState, {
  [getProgramDetailRequest.type]: (state) => {
    state.loading = true;
  },
  [getProgramcDetailSuccess.type]: (state, action) => {
    state.loading = false;
    state.programDetail = action.payload;
  },
  [getProgramDetailFailed.type]: (state) => {
    state.loading = false;
  },
  [resetProgramData.type]: (state) => {
    state.loading = false;
    state.programDetail = undefined;
  },
  [postAddProgramRequest.type]: (state) => {
    state.loading = true;
  },
  [postAddProgramSuccess.type]: (state) => {
    state.loading = false;
  },
  [postAddProgramFailed.type]: (state) => {
    state.loading = false;
  },
  [patchProgramRequest.type]: (state) => {
    state.loading = true;
  },
  [patchProgramSuccess.type]: (state) => {
    state.loading = false;
  },
  [patchProgramFailed.type]: (state) => {
    state.loading = false;
  },
  [getProgramIconRequest.type]: (state) => {
    state.loadingProgramIconList = true;
  },
  [getProgramIconSuccess.type]: (state, action) => {
    state.loadingProgramIconList = false;
    state.programIcon = action.payload['program-icons'];
  },
  [getProgramIconFailed.type]: (state) => {
    state.loadingProgramIconList = false;
  },
  [getEpisodeIconRequest.type]: (state) => {
    state.loadingEpisodeIconList = true;
  },
  [getEpisodeIconSuccess.type]: (state, action) => {
    state.loadingEpisodeIconList = false;
    state.episodeIcon = action.payload['episode-icons'];
  },
  [getEpisodeIconFailed.type]: (state) => {
    state.loadingEpisodeIconList = false;
  },
  [getBroadCastListRequest.type]: (state) => {
    state.loadingBroadCastList = true;
  },
  [getBroadCastListSuccess.type]: (state, action) => {
    state.loadingBroadCastList = false;
    state.broadCastList = action.payload.broadcast;
  },
  [getBroadCastListFailed.type]: (state) => {
    state.loadingBroadCastList = false;
  },
  [deleteEpisodeRequest.type]: (state) => {
    state.loadingDeleteEpisode = true;
  },
  [deleteEpisodeSuccess.type]: (state) => {
    state.loadingDeleteEpisode = false;
  },
  [deleteEpisodeFailed.type]: (state) => {
    state.loadingDeleteEpisode = false;
  },
  [patchEpisodeRequest.type]: (state) => {
    state.loadingEpisodeEdit = true;
  },
  [patchEpisodeSuccess.type]: (state) => {
    state.loadingEpisodeEdit = false;
  },
  [patchEpisodeFailed.type]: (state) => {
    state.loadingEpisodeEdit = false;
  },
  [addEpisodeRequest.type]: (state) => {
    state.loadingEpisode = true;
  },
  [addEpisodeSuccess.type]: (state) => {
    state.loadingEpisode = false;
  },
  [addEpisodeFailed.type]: (state) => {
    state.loadingEpisode = false;
  },
  [getProductListRequest.type]: (state) => {
    state.loadingProductList = true;
  },
  [getProductListSuccess.type]: (state, action) => {
    state.loadingProductList = false;
    state.productList = action.payload;
  },
  [getProductListFailed.type]: (state) => {
    state.loadingProductList = false;
  },
  [patchTableJSONRequest.type]: (state) => {
    state.loadingPatchTable = true;
  },
  [patchTableJSONSuccess.type]: (state) => {
    state.loadingPatchTable = false;
  },
  [patchTableJSONFailed.type]: (state) => {
    state.loadingPatchTable = false;
  },
  [deleteTableJSONRequest.type]: (state) => {
    state.loadingDeleteTable = true;
  },
  [deleteTableJSONSuccess.type]: (state) => {
    state.loadingDeleteTable = false;
  },
  [deleteTableJSONFailed.type]: (state) => {
    state.loadingDeleteTable = false;
  },
});
