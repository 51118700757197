import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { Box, Flex } from '@chakra-ui/layout';
import WBLoader from 'components/WBLoader';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
interface IFile extends File {
  preview: string;
}

export default function Previews({ setFile, files, removeFile }) {
  const { t } = useTranslation();
  const [localFile, setLocalFile] = useState<any>([]);
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'],
    maxSize: 5242880,
    onDrop: (acceptedFiles) => {
      setLocalFile(
        acceptedFiles.map((file: File) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }),
      );
    },

    preventDropOnDocument: true,
  });

  const onRemoveFile = () => {
    removeFile();
    setLocalFile([]);
  };

  const thumbs = localFile?.map((file: IFile) => (
    <Flex key={file.name}>
      <Box position="relative" width="100%" background="#333" height="100%" className="image-area">
        <Image maxWidth="400px" maxHeight="auto" alt="preview" src={file?.preview} />
        <Button right="-25px" top="-25px" variant="btnCirleRemove" onClick={onRemoveFile} className="remove-image">
          &#215;
        </Button>
      </Box>
    </Flex>
  ));

  useEffect(() => {
    return () => {
      localFile?.file?.forEach((file: IFile) => URL.revokeObjectURL(file?.preview));
      files?.file?.forEach((file: IFile) => URL.revokeObjectURL(file?.preview));
    };
  }, [files?.file, localFile?.file]);

  useEffect(() => {
    if (isEmpty(files)) {
      setTimeout(() => {
        setFile(localFile);
      }, 500);
    }
  }, [localFile, setFile, files]);

  return (
    <>
      {isEmpty(files) && !isEmpty(localFile) ? (
        <WBLoader />
      ) : (
        <Flex
          style={{
            border: '1px dashed #d2d2d2',
            backgroundColor: '#f6f6f6',
            color: ' #d2d2d2',
            display: 'flex',
            padding: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '15px 0px',
            flexDirection: 'column',
          }}
          className="container"
        >
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            {...getRootProps({ className: 'dropzone' })}
          >
            <input {...getInputProps()} onClick={() => setFile(localFile)} />
            <Box>{t('common:UploadProgramImage')}</Box>

            {isEmpty(localFile) && <em>{t('common:OnlyJPGPNG')}</em>}
          </Flex>
          {!isEmpty(localFile) && (
            <Box style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 16 }}>{thumbs}</Box>
          )}
        </Flex>
      )}
    </>
  );
}
