import { Tab, TabList, Tabs, TabPanels, TabPanel, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FC, useState } from 'react';
import WBContainer from 'components/WBContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getBroadCastListRequest,
  getEpisodeIconRequest,
  getProgramDetailRequest,
  resetProgramData,
} from 'redux/ProgramDetail/actions';
import {
  broadCastListLoading,
  episodeIconListLoading,
  programDetail,
  programDetailLoading,
  programIconListLoading,
} from 'redux/ProgramDetail/selectors';
import ProgramDetailPage from './ProgramDetails';
import ProgramEpisodesList from './ProgramEpisodesList';
import isEmpty from 'lodash/isEmpty';
import WBLoader from 'components/WBLoader';

const EditProgramPage: FC = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const program = useSelector(programDetail);
  const loading = useSelector(programDetailLoading);
  const programIconLoading = useSelector(programIconListLoading);
  const episodeLoading = useSelector(episodeIconListLoading);
  const broadCastLoading = useSelector(broadCastListLoading);

  const [data, setData] = useState(program);

  useEffect(() => {
    if (id) {
      dispatch(getProgramDetailRequest({ id }));
    }
    dispatch(getBroadCastListRequest());
    dispatch(getEpisodeIconRequest());
  }, [dispatch, id]);

  useEffect(() => {
    setData(program);
  }, [program]);

  useEffect(() => {
    dispatch(resetProgramData());
  }, [dispatch]);

  if (loading || programIconLoading || episodeLoading || broadCastLoading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        backgroundColor="shadowGreyCard4"
      >
        <WBLoader />
      </Flex>
    );
  }

  return (
    <WBContainer>
      <Tabs>
        <TabList>
          <Tab>Program Detail</Tab> {/* WIP: translate */}
          <Tab>Program Episodes</Tab> {/* WIP: translate */}
        </TabList>
        <TabPanels>
          <TabPanel>{!isEmpty(data) && <ProgramDetailPage />}</TabPanel>
          <TabPanel>{!isEmpty(data) && <ProgramEpisodesList />}</TabPanel>
        </TabPanels>
      </Tabs>
    </WBContainer>
  );
};

export default EditProgramPage;
