import * as AuthAPI from '../api';

const SESSION_STORAGE_AUTH_TOKEN = 'obi_authToken';
const SESSION_STORAGE_REFRESH_TOKEN = 'obi_refreshToken';

/**
 * Store the passed authTokens to SessionStorage for later use.
 *
 * @param accessToken the JWT access token to store
 * @param refreshToken the refresh token to store
 */
export async function storeAuthTokens(
  accessToken: string,
  refreshToken = '',
): Promise<{ accessToken: string; refreshToken: string }> {
  try {
    localStorage.setItem(SESSION_STORAGE_AUTH_TOKEN, accessToken);
    localStorage.setItem(SESSION_STORAGE_REFRESH_TOKEN, refreshToken);
  } catch (err) {
    console.error('Failed to store AuthTokens', err);
  }

  return {
    accessToken,
    refreshToken,
  };
}

/**
 * Clear currently saved authToken from SessionStorage.
 */
export async function clearAuthTokens(): Promise<void> {
  try {
    localStorage.removeItem(SESSION_STORAGE_AUTH_TOKEN);
    localStorage.removeItem(SESSION_STORAGE_REFRESH_TOKEN);
  } catch (err) {
    console.error('Failed to clear AuthTokens', err);
  }
}

/**
 * Retrieve the stored accessToken (if any)
 */
export async function getAccessToken(): Promise<string> {
  try {
    const sessionToken = await localStorage.getItem(SESSION_STORAGE_AUTH_TOKEN);
    if (sessionToken) {
      return sessionToken;
    }
    return '';
  } catch (err) {
    console.error('Failed to retrieve AccessToken', err);
  }

  return '';
}

/**
 * Retrieve the stored refreshToken (if any)
 */
async function getRefreshToken(): Promise<string> {
  try {
    const sessionRefreshToken = await localStorage.getItem(SESSION_STORAGE_REFRESH_TOKEN);

    if (sessionRefreshToken) {
      return sessionRefreshToken;
    }
    return '';
  } catch (err) {
    console.error('Failed to retrieve RefreshToken', err);
  }

  return '';
}

/**
 * Call the refreshToken API to refresh the current tokens and store the new ones.
 */
export async function refreshAuthTokens(): Promise<{ accessToken: string; refreshToken: string }> {
  const oldRefreshToken = await getRefreshToken();

  try {
    const { token, refresh_token } = await AuthAPI.refreshToken({
      refreshToken: oldRefreshToken,
    });

    if (token && refresh_token) {
      await storeAuthTokens(token, refresh_token);

      return {
        accessToken: token,
        refreshToken: refresh_token,
      };
    }
    //  else {
    //   store.dispatch(logoutResetData());
    // navigationRef.current?.navigate('LoginPage');
    // }
  } catch (err) {
    console.error('Failed to refresh AuthTokens', err);

    await clearAuthTokens();

    // store.dispatch(logoutResetData());
    // navigationRef.current?.navigate('LoginPage');

    throw err;
  }

  await clearAuthTokens();

  // store.dispatch(logoutResetData());
  // navigationRef.current?.navigate('LoginPage');

  return {
    accessToken: '',
    refreshToken: '',
  };
}
