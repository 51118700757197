import * as React from 'react';
import { FC, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import WBContainer from 'components/WBContainer';
import { Flex, Heading, Input, Textarea, Text, Box, Button, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import WBSelect from 'components/WBSelect';
import { useDispatch, useSelector } from 'react-redux';
import WBLoader from 'components/WBLoader';
import { useState } from 'react';
import { useCallback } from 'react';
import {
  editNotificationRequest,
  getNotificationRequest,
  postNewNotificationRequest,
} from 'redux/Notification/actions';
import { allProgramListInNotification, allProgramListLoading } from 'redux/ProgramList/selectors';
import { getAllProgramListRequest } from 'redux/ProgramList/actions';
import Previews from 'components/WBDropzonePreview';
import { computeChecksumMd5, isValidURL } from 'utils';
import { isEmpty } from 'lodash';
import { history } from 'routes/history';
import { notificationDetail, notificationDetailLoading } from 'redux/Notification/selectors';
import { INotification } from 'redux/Notification/types';
import { SingleProgram } from 'redux/ProgramList/types';

const initialStateNotification = {
  description: '',
  episode_id: 0,
  episode_name: '',
  product_icon_url: '',
  product_placement_id: 0,
  product_url: '',
  program_id: 0,
  program_name: '',
  send_at: '',
  status: 0,
  title: '',
  type: '',
  type_id: 0,
  brand: '',
};

const CreateNotification: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const programList = useSelector(allProgramListInNotification);
  const programListLoading = useSelector(allProgramListLoading);
  const not = useSelector(notificationDetail);
  const notificationLoading = useSelector(notificationDetailLoading);
  const [selectedProduct, setSelectedProgram] = useState<{ label: string; value: number | undefined }>();
  const [selectedNotification, setSelectedNotification] = useState<{ label: string; value: number }>();
  const [notification, setNotification] = useState<INotification>(initialStateNotification);
  const [files, setFiles] = useState<any>([]);

  const isValidated = !!(
    notification?.title &&
    notification?.description &&
    (selectedProduct?.value || notification?.id) &&
    (notification?.product_icon_url || !isEmpty(files)) &&
    selectedProduct &&
    selectedNotification &&
    isValidURL(notification?.product_url) &&
    notification?.brand
  );

  const notification_type_id = useMemo(
    () => [
      { label: 'BRAND', value: 1 },
      { label: 'LIKE', value: 2 },
      { label: 'SUGGESTION', value: 3 },
      { label: 'SPONSORSHIP', value: 4 },
    ],
    [],
  );

  const onFactorySelect = useCallback((list) => {
    return !isEmpty(list)
      ? list.map((l: SingleProgram) => {
          return {
            value: l?.id,
            label: `${l.program_name} - ${l?.name}`,
          };
        })
      : [];
  }, []);

  const checkUrl = useCallback(() => {
    return isValidURL(notification?.product_url);
  }, [notification?.product_url]);

  const onSetFile = useCallback(async (file) => {
    const blob = new Blob(file, { type: 'text/plain' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    const hash = await computeChecksumMd5(file);
    reader.onloadend = function () {
      const base64String = reader?.result?.toString().split(',')[1];
      setFiles({ file, base64String, hash });
    };
  }, []);

  const onRemoveFile = useCallback(() => {
    setFiles([]);
  }, []);

  const onSendNotification = useCallback(() => {
    const passIcon = files?.file
      ? {
          files: [
            {
              filename: !isEmpty(!files?.file) && files?.file[0]?.path,
              hash: files?.hash,
              filestream: files?.base64String,
            },
          ],
        }
      : {};

    const episodeId = selectedProduct?.value;
    const notificationId = selectedNotification?.value;

    const notificationObj = {
      name: notification?.title,
      details: notification?.description,
      episode_id: episodeId || notification?.id,
      notification_type_id: notificationId || notification?.type_id,
      url: notification?.product_url,
      note: notification?.note,
      icon: passIcon,
      brand: notification.brand,
    };

    if (id) dispatch(editNotificationRequest({ ...notificationObj, id: id }));
    if (!id) dispatch(postNewNotificationRequest({ ...notificationObj }));
    history.push(`/notifications`);
  }, [
    dispatch,
    files?.base64String,
    files?.file,
    files?.hash,
    id,
    notification?.brand,
    notification?.description,
    notification?.id,
    notification?.note,
    notification?.product_url,
    notification?.title,
    notification?.type_id,
    selectedNotification?.value,
    selectedProduct?.value,
  ]);

  const onSelectProgram = useCallback((select) => {
    return !isEmpty(select) ? setSelectedProgram(select) : [];
  }, []);

  const onSelectedNotification = useCallback((select) => {
    setSelectedNotification(select);
  }, []);

  const controlForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setNotification({ ...notification, [name]: value });
    },
    [notification],
  );

  useEffect(() => {
    dispatch(getAllProgramListRequest({}));
  }, [dispatch]);

  useEffect(() => {
    if (id) dispatch(getNotificationRequest({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      setSelectedNotification({ label: not?.type, value: not?.type_id });
      setSelectedProgram({ label: not?.program_name, value: not?.episode_id });
      setNotification({
        ...not,
        episode_id: notification?.id,
        episode_name: notification?.episode_name,
        program_id: notification.program_id,
        program_name: notification.program_name,
      });
    }
  }, [id, not, notification?.episode_name, notification?.id, notification.program_id, notification.program_name]);

  return (
    <WBContainer>
      {!programListLoading || !notificationLoading ? (
        <Box>
          <Flex justifyContent="space-between">
            <Heading mb={6}>{t('CreateNotification')}</Heading>
          </Flex>
          <Flex flexDirection="column">
            <Text fontSize="xl">{t('common:Title')}*</Text>
            <Input name="title" value={notification?.title} onChange={controlForm} />

            <Text fontSize="xl" pt="20px">
              {t('common:Description')}*
            </Text>
            <Textarea name="description" value={notification?.description} onChange={controlForm} />

            <Text pt="20px" fontSize="xl">
              {t('common:Note')}
            </Text>
            <Input name="note" value={notification?.note} onChange={controlForm} />

            <Text pt="20px" fontSize="xl">
              {t('common:Brand*')}
            </Text>
            <Input name="brand" value={notification?.brand} onChange={controlForm} />

            <Text fontSize="xl" pt="20px">
              {t('common:Program_Episode')}*
            </Text>
            <WBSelect
              isMulti={false}
              name="program_id"
              options={onFactorySelect(programList)}
              onChange={onSelectProgram}
              value={selectedProduct}
            />
            <Text fontSize="xl" pt="20px">
              {t('common:NotificationType')}*
            </Text>

            <WBSelect
              isMulti={false}
              name="type_id"
              options={notification_type_id}
              value={selectedNotification}
              onChange={onSelectedNotification}
            />
          </Flex>

          <Text fontSize="xl" pt="20px">
            {t('common:StoreUrl')}*
          </Text>

          <Input name="product_url" onBlur={checkUrl} value={notification?.product_url} onChange={controlForm} />
          {notification?.product_url && !isValidURL(notification?.product_url) && (
            <Text fontSize="sm" pt="5px" color="red">
              {t('ErrorURLHTTP')}
            </Text>
          )}
          <Text fontSize="xl" pt="20px">
            {t('common:ImageProduct')}*
          </Text>
          <Previews setFile={onSetFile} files={files?.file} removeFile={onRemoveFile} />
          {notification?.product_icon_url && isEmpty(files.file) && (
            <Flex alignSelf="center" justifyContent="center">
              <Image src={notification?.product_icon_url} width="200px" height="200px" />
            </Flex>
          )}

          <Flex
            justifyContent="flex-end"
            alignItems="center"
            w="100%"
            backgroundColor="white"
            rounded={6}
            mb={5}
            mt={10}
          >
            <Button
              onClick={onSendNotification}
              variant={isValidated ? 'secondaryOutline' : 'disabled'}
              size="primaryOutlineSmall"
              width="300px"
              isDisabled={!isValidated}
            >
              {t('common:SaveNotification')}
            </Button>
          </Flex>
        </Box>
      ) : (
        <WBLoader />
      )}
    </WBContainer>
  );
};

export default CreateNotification;
