import { APIClient } from 'common/utils';
import { deleteProgramRequestPayload, getAllProgramListRequestPayload } from './types';

export async function getAllProgramList({
  limit = 99999,
  page = 1,
}: getAllProgramListRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/programs?limit=${limit}&page=${page}`);
    return resp.data;
  } catch (error) {
    console.error('getAllProgramList - Error: ', error);
    throw error;
  }
}
export async function deleteProgram({ id }: deleteProgramRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.patch(`${process.env.REACT_APP_BASE_API_URL}/programs/${id}/delete`);
    return resp.data;
  } catch (error) {
    console.error('deleteProgram - Error: ', error);
    throw error;
  }
}
