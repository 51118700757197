export interface IMatch {
  path: string;
  url: string;
  isExact: boolean;
  params: any;
}

export interface ILocation {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: any;
}

export const STATUS_CONSTANTS = {
  SENT: 1,
  DRAFT: 0,
  DELETED: -1,
};
