/* istanbul ignore file */

// import env from '@env';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import {
  refreshAuthenticationInterceptor,
  requestSuccessInterceptor,
  requestErrorInterceptor,
  responseSuccessInterceptor,
  responseErrorInterceptor,
} from './interceptors';

// AXIOS

export const APIClient = axios.create({
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

// Instantiate the auth refresh interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(APIClient, refreshAuthenticationInterceptor);

// Set request interceptor to add Auth token
APIClient.interceptors.request.use(requestSuccessInterceptor, requestErrorInterceptor);

APIClient.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
