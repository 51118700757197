import { AuthContext } from 'auth/types/authContext';
import { clearAuthTokens, getAccessToken, storeAuthTokens } from 'auth/utils/token';
import { useCallback, useEffect, useState } from 'react';
import { history } from 'routes/history';
import * as AuthAPI from '../api';
// import type { AuthContext } from '../types/authContext';
// import { clearAuthTokens, getAccessToken, storeAuthTokens } from '../utils/token';

// Provider hook that creates auth object and handles state
export function useProvideAuthContext(): AuthContext {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(true);

  const login = useCallback(async (username: string, password: string) => {
    // Call login API
    const { accessToken, refreshToken } = await AuthAPI.login({
      username,
      password,
    });

    if (accessToken) {
      storeAuthTokens(accessToken, refreshToken);
      setIsAuthenticated(true);

      history.push('/programs');
    }
  }, []);

  const logout = useCallback(() => {
    // Clear stored tokens
    clearAuthTokens();

    // setUser({});
    setIsAuthenticated(false);
  }, []);

  // Load user token from SessionStorage if available
  useEffect(() => {
    const retrieveToken = async () => {
      const existingUserToken = await getAccessToken();
      // console.log('existingUserToken: ', !isAuthenticated, !!existingUserToken);

      // eslint-disable-next-line no-extra-boolean-cast
      if (!!existingUserToken) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }

      setLoading(false);
    };

    retrieveToken();
  }, [isAuthenticated]);

  // Return an auth context
  return {
    isAuthenticated,
    isLoading,
    login,
    logout,
  };
}
