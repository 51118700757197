import { createAction } from '@reduxjs/toolkit';
import {
  deleteNotificationRequestPayload,
  editNotificationPayload,
  filterNotificationRequestPayload,
  filterNotificationSuccessPayload,
  getAllNotificationListRequestPayload,
  getAllNotificationListSuccessPayload,
  getNotificationRequestPayload,
  getNotificationSuccessPayload,
  postNewNotificationByIdPayload,
  postNewNotificationPayload,
} from './types';

export const getAllNotificationListRequest =
  createAction<getAllNotificationListRequestPayload>('GET_ALL_NOTIFY_LIST_REQUEST');
export const getAllNotificationListSuccess =
  createAction<getAllNotificationListSuccessPayload>('GET_ALL_NOTIFY_LIST_SUCCESS');
export const getAllNotificationListFailed = createAction('GET_ALL_NOTIFY_LIST_FAILED');

export const postNewNotificationRequest = createAction<postNewNotificationPayload>('POST_NOTIFICATION_REQUEST');
export const postNewNotificationSuccess = createAction('POST_NOTIFICATION_SUCCESS');
export const postNewNotificationFailed = createAction('POST_NOTIFICATION_FAILED');

export const editNotificationRequest = createAction<editNotificationPayload>('EDIT_NOTIFICATION_REQUEST');
export const editNotificationSuccess = createAction('EDIT_NOTIFICATION_SUCCESS');
export const editNotificationFailed = createAction('EDIT_NOTIFICATION_FAILED');

export const postNewNotificationByIdRequest = createAction<postNewNotificationByIdPayload>(
  'POST_NOTIFICATION_BY_ID_REQUEST',
);
export const postNewNotificationByIdSuccess = createAction('POST_NOTIFICATION_BY_ID_SUCCESS');
export const postNewNotificationByIdFailed = createAction('POST_NOTIFICATION_BY_ID_FAILED');

export const deleteNotificationRequest = createAction<deleteNotificationRequestPayload>('DELETE_NOTIFICATION_REQUEST');
export const deleteNotificationSuccess = createAction('DELETE_NOTIFICATION_SUCCESS');
export const deleteNotificationFailed = createAction('DELETE_NOTIFICATION_FAILED');

export const getNotificationRequest = createAction<getNotificationRequestPayload>('GET_NOTIFICATION_REQUEST');
export const getNotificationSuccess = createAction<getNotificationSuccessPayload>('GET_NOTIFICATION_SUCCESS');
export const getNotificationFailed = createAction('GET_NOTIFICATION_FAILED');

export const filterNotificationRequest = createAction<filterNotificationRequestPayload>('FILTER_NOTIFICATION_REQUEST');
export const filterNotificationSuccess = createAction<filterNotificationSuccessPayload>('FILTER_NOTIFICATION_SUCCESS');
export const filterNotificationFailed = createAction('FILTER_NOTIFICATION_FAILED');
