import { createAction } from '@reduxjs/toolkit';
import { deleteProgramRequestPayload, getAllProgramListRequestPayload, getAllProgramListSuccessPayload } from './types';

export const getAllProgramListRequest = createAction<getAllProgramListRequestPayload>('GET_ALL_PROGRAM_LIST_REQUEST');
export const getAllProgramListSuccess = createAction<getAllProgramListSuccessPayload>('GET_ALL_PROGRAM_LIST_SUCCESS');
export const getAllProgramListFailed = createAction('GET_ALL_PROGRAM_LIST_FAILED');

export const deleteProgramRequest = createAction<deleteProgramRequestPayload>('DELETE_PROGRAM_REQUEST');
export const deleteProgramSuccess = createAction('DELETE_PROGRAM_SUCCESS');
export const deleteProgramFailed = createAction('DELETE_PROGRAM_FAILED');
