import { createReducer } from '@reduxjs/toolkit';
import {
  getAllProgramListRequest,
  getAllProgramListSuccess,
  getAllProgramListFailed,
  deleteProgramRequest,
  deleteProgramSuccess,
  deleteProgramFailed,
} from './actions';

export type SingleProgram = {
  id: number;
  name: string;
  description: string;
  preferred: boolean;
  start_at: string;
  end_at: string;
  program_id: string;
  program_name: string;
  program_details: string;
  program_icon: string;
  episode_icon: string;
  broadcast: string;
};
export interface IProgramsState {
  loadingDeleteProgram: boolean;
  loading: boolean;
  total_elements: number;
  currentPage: number;
  episodes: SingleProgram[];
}

const initialState: IProgramsState = {
  loadingDeleteProgram: false,
  loading: false,
  total_elements: 0,
  episodes: [],
  currentPage: 1,
  //   limit: 20,
  //   page: 1,
  //   pages: 0,
  //   total: 0,
};

export const allProgramReducer = createReducer(initialState, {
  [getAllProgramListRequest.type]: (state) => {
    state.loading = true;
  },
  [getAllProgramListSuccess.type]: (state, action) => {
    state.loading = false;
    state.total_elements = action.payload.total_elements;
    state.currentPage = action.payload.currentPage;
    state.episodes = action.payload.elements.episodes;
  },
  [getAllProgramListFailed.type]: (state) => {
    state.loading = false;
  },
  [deleteProgramRequest.type]: (state) => {
    state.loadingDeleteProgram = true;
  },
  [deleteProgramSuccess.type]: (state) => {
    state.loadingDeleteProgram = false;
  },
  [deleteProgramFailed.type]: (state) => {
    state.loadingDeleteProgram = false;
  },
});
