import { APIClient } from 'common/utils';
import {
  deleteProductRequestPayload,
  getAllProductListRequestPayload,
  getProductDetailRequestPayload,
  patchProductRequestPayload,
  postAddProductRequestPayload,
} from './types';

export async function getAllProductList({ limit = 10, page = 1 }: getAllProductListRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/products?limit=${limit}&page=${page}`);
    return resp.data;
  } catch (error) {
    console.error('getAllProductList - Error: ', error);
    throw error;
  }
}
export async function deleteProduct({ id }: deleteProductRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.patch(`${process.env.REACT_APP_BASE_API_URL}/products/${id}/delete`);
    return resp.data;
  } catch (error) {
    console.error('deleteProduct - Error: ', error);
    throw error;
  }
}
export async function getProductDetail({ id }: getProductDetailRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/products/${id}`);
    return resp.data;
  } catch (error) {
    console.error('getProductDetail - Error: ', error);
    throw error;
  }
}
export async function postAddProduct(product: postAddProductRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.post(`${process.env.REACT_APP_BASE_API_URL}/products`, { ...product });
    return resp.data;
  } catch (error) {
    console.error('postAddProduct - Error: ', error);
    throw error;
  }
}
export async function patchProduct({ id, editProduct }: patchProductRequestPayload): Promise<Response> {
  try {
    const resp = await APIClient.patch(`${process.env.REACT_APP_BASE_API_URL}/products/${id}`, { ...editProduct });
    return resp.data;
  } catch (error) {
    console.error('patchProduct - Error: ', error);
    throw error;
  }
}
export async function getBrandList(): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/brands`);
    return resp.data;
  } catch (error) {
    console.error('getBrandList - Error: ', error);
    throw error;
  }
}
export async function getSubCategoryList(): Promise<Response> {
  try {
    const resp = await APIClient.get(`${process.env.REACT_APP_BASE_API_URL}/products_subcategories`);
    return resp.data;
  } catch (error) {
    console.error('getSubCategory - Error: ', error);
    throw error;
  }
}
