import { SystemStyleObject } from '@chakra-ui/react';
import React, { FC } from 'react';
import Select, { Props as SelectProps } from 'react-select';

interface WBSelectProps {
  isMulti: boolean;
  name?: string;
  options: unknown;
  value?: any;
  styles?: SystemStyleObject;
  hasErrors?: boolean;
  onChange: (arg: 0) => void;
  loading?: boolean;
  closeMenuOnSelect?: boolean;
}

const WBSelect: FC<WBSelectProps & SelectProps> = ({
  isMulti = false,
  name = '',
  options = [],
  value = undefined,
  styles = {},
  hasErrors = false,
  onChange,
  loading = false,
  closeMenuOnSelect = true,
  ...props
}) => {
  return (
    <Select
      isMulti={isMulti}
      isLoading={loading}
      name={name}
      options={options}
      closeMenuOnSelect={closeMenuOnSelect}
      value={value}
      onChange={onChange}
      noOptionsMessage={() => 'select'}
      {...props}
    />
  );
};

export default WBSelect;
