import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { messageHandlerSet } from 'redux/messageHandler/actions';
import {
  getAllProductListRequest,
  getAllProductListSuccess,
  getAllProductListFailed,
  deleteProductSuccess,
  deleteProductFailed,
  deleteProductRequest,
  patchProductSuccess,
  getProductDetailRequest,
  patchProductFailed,
  patchProductRequest,
  getProductDetailFailed,
  postAddProductSuccess,
  getProductDetailSuccess,
  postAddProductFailed,
  postAddProductRequest,
  getBrandListRequest,
  getBrandListSuccess,
  getBrandListFailed,
  getSubCategoryListSuccess,
  getSubCategoryListFailed,
  getSubCategoryListRequest,
} from './actions';
import * as FilmsAPI from './apiCall';
import {
  deleteProductRequestPayload,
  getAllProductListRequestPayload,
  getProductDetailRequestPayload,
  patchProductRequestPayload,
  postAddProductRequestPayload,
} from './types';
import i18n from '../../i18n';

function* getAllProductListSaga({ payload }: PayloadAction<getAllProductListRequestPayload>) {
  try {
    const productListRes = yield call(FilmsAPI.getAllProductList, { ...payload });
    yield put(getAllProductListSuccess(productListRes));
  } catch (err: any) {
    yield put(getAllProductListFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noProductListFound') }));
  }
}
function* deleteProductSaga({ payload }: PayloadAction<deleteProductRequestPayload>) {
  try {
    yield call(FilmsAPI.deleteProduct, { ...payload });
    yield put(deleteProductSuccess());
    yield put(getAllProductListRequest({ limit: 10 }));
  } catch (err: any) {
    yield put(deleteProductFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithDeleteProduct') }),
    );
  }
}

function* patchProductSaga({ payload }: PayloadAction<patchProductRequestPayload>) {
  try {
    const productRes = yield call(FilmsAPI.patchProduct, { ...payload });
    const { id } = productRes;

    yield put(patchProductSuccess());
    yield put(getProductDetailRequest({ id }));
  } catch (err: any) {
    yield put(patchProductFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithEditProduct') }),
    );
  }
}

function* getProductDetailSaga({ payload }: PayloadAction<getProductDetailRequestPayload>) {
  try {
    const productDetailRes = yield call(FilmsAPI.getProductDetail, { ...payload });
    yield put(getProductDetailSuccess(productDetailRes));
  } catch (err: any) {
    yield put(getProductDetailFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noProductDetailFound') }));
  }
}

function* postAddProductSaga({ payload }: PayloadAction<postAddProductRequestPayload>) {
  try {
    yield call(FilmsAPI.postAddProduct, { ...payload });
    yield put(postAddProductSuccess());
    yield put(getAllProductListRequest({}));
  } catch (err: any) {
    yield put(postAddProductFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithCreateProduct') }),
    );
  }
}

function* getBrandListSaga() {
  try {
    const brandList = yield call(FilmsAPI.getBrandList);
    yield put(getBrandListSuccess(brandList));
  } catch (err: any) {
    yield put(getBrandListFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noBrandListFound') }));
  }
}
function* getSubCategoryListSaga() {
  try {
    const subCategory = yield call(FilmsAPI.getSubCategoryList);
    yield put(getSubCategoryListSuccess(subCategory));
  } catch (err: any) {
    yield put(getSubCategoryListFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noSubCategoryFound') }));
  }
}
function* productSaga() {
  yield takeLatest(getAllProductListRequest.type, getAllProductListSaga);
  yield takeLatest(deleteProductRequest.type, deleteProductSaga);
  yield takeLatest(patchProductRequest.type, patchProductSaga);
  yield takeLatest(getProductDetailRequest.type, getProductDetailSaga);
  yield takeLatest(postAddProductRequest.type, postAddProductSaga);
  yield takeLatest(getBrandListRequest.type, getBrandListSaga);
  yield takeLatest(getSubCategoryListRequest.type, getSubCategoryListSaga);
}

export default productSaga;
