import { Reducer } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import messageHandler from './messageHandler/reducers';
import { history } from 'routes/history';
import { allProgramReducer } from './ProgramList/reducers';
import { notificationReducer } from './Notification/reducers';
import { programDetailReducer } from './ProgramDetail/reducers';
import { allProductReducer } from './ProductList/reducers';

const reducers = {
  programList: allProgramReducer,
  productList: allProductReducer,
  notificationList: notificationReducer,
  programDetail: programDetailReducer,
  messages: messageHandler,
};

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [],
  blacklist: ['router'],
};

// Reducers
const allReducers = {
  ...reducers,
  router: connectRouter(history) as Reducer, // to fix CombineState and type incompatibility
};

const persistedRootReducer = persistCombineReducers(persistConfig, allReducers);

export type RootState = ReturnType<typeof persistedRootReducer>;

export default persistedRootReducer;
