import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/reducers';
import { elementsGetProductList } from './types';

const programDetailSelector = (state: RootState) => state.programDetail;

export const programDetailLoading = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.loading,
);
export const loadingEpisodeEdit = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.loadingEpisodeEdit,
);
export const programIconListLoading = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.loadingProgramIconList,
);
export const episodeIconListLoading = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.loadingEpisodeIconList,
);
export const broadCastListLoading = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.loadingBroadCastList,
);

export const deleteEpisodeLoading = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.loadingDeleteEpisode,
);
export const programDetail = createSelector(programDetailSelector, (programDetailState) => {
  return programDetailState.programDetail;
});
export const episodeDetails = createSelector(programDetailSelector, (programDetailState) => {
  return programDetailState.programDetail?.episodes;
});
export const programIcon = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.programIcon,
);
export const episodeIcon = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.episodeIcon,
);
export const broadCastList = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.broadCastList,
);
export const productList = createSelector(programDetailSelector, (programDetailState) => {
  return programDetailState?.productList?.elements?.map((p: elementsGetProductList) => {
    return {
      label: p.name,
      value: p.id,
    };
  });
});
export const productListLoading = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.loadingProductList,
);
export const deleteTableLoading = createSelector(
  programDetailSelector,
  (programDetailState) => programDetailState.loadingDeleteTable,
);
