import React, { useCallback, useState, useEffect } from 'react';
import { Button, Flex, Text, Input, Textarea, Box, Image, Heading, Checkbox } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { useDispatch, useSelector } from 'react-redux';
import WBSelect from 'components/WBSelect';
import Previews from 'components/WBDropzonePreview';
import { computeChecksumMd5, isValidURL } from 'utils';
import { isEmpty } from 'lodash';
import WBContainer from 'components/WBContainer';
import { history } from 'routes/history';
import { getBrandListRequest, getSubCategoryListRequest, postAddProductRequest } from 'redux/ProductList/actions';
import { allSubCategoryList } from 'redux/ProductList/selectors';

const initialStateRows = {
  name: '',
  subcategory: 0,
  brand: '',
  url: '',
  details: '',
  trend: false,
  icon: undefined,
};

const AddProductPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const subCategoryList = useSelector(allSubCategoryList);

  const [data, setData] = useState(initialStateRows);
  const [files, setFiles] = useState<any>([]);
  const [subCategories, setSelectedSubCategory] = useState<{ label: string; value: number }>();
  const [isTrend, setIsTrend] = useState<boolean>(false);

  const isValidated = !!(
    data?.name &&
    subCategories &&
    data?.brand &&
    data?.url &&
    data?.details &&
    (data?.icon || !isEmpty(files))
  );

  const onSetFile = useCallback(async (file) => {
    const blob = new Blob(file, { type: 'text/plain' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    const hash = await computeChecksumMd5(file);
    reader.onloadend = () => {
      const base64String = reader?.result?.toString().split(',')[1];
      setFiles({ file, base64String, hash });
    };
  }, []);

  const onRemoveFile = useCallback(() => {
    setFiles([]);
  }, []);

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  const sendAddProduct = useCallback(() => {
    const addProduct = {
      name: data?.name,
      product_subcategory_id: subCategories?.value,
      brand: data?.brand,
      url: data?.url,
      details: data?.details,
      trend: isTrend ? 1 : 0,
      icon: {
        files: [
          {
            filename: files?.file[0]?.path,
            hash: files?.hash,
            filestream: files?.base64String,
          },
        ],
      },
    };
    dispatch(postAddProductRequest(addProduct));
    goBack();
  }, [
    data?.brand,
    data?.details,
    data?.name,
    data?.url,
    dispatch,
    files?.base64String,
    files?.file,
    files?.hash,
    goBack,
    isTrend,
    subCategories,
  ]);

  const controlForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const onSelectSubCategory = useCallback((selectedSubCategory) => {
    setSelectedSubCategory(selectedSubCategory);
  }, []);

  const onFactorySelect = useCallback((list: { id: number; name: string }[]) => {
    return !isEmpty(list)
      ? list?.map((l: { id: number; name: string }) => {
          return {
            value: l?.id,
            label: l?.name,
          };
        })
      : [];
  }, []);

  const checkUrl = useCallback(() => {
    return isValidURL(data?.url);
  }, [data?.url]);

  const checkBox = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsTrend(e.target.checked);
    },
    [setIsTrend],
  );

  useEffect(() => {
    dispatch(getBrandListRequest());
    dispatch(getSubCategoryListRequest());
  }, [dispatch]);

  return (
    <WBContainer>
      <Heading display="block" mb={6}>
        {t('common:AddProduct')}
      </Heading>
      <Flex w="80%" direction="column" backgroundColor="white">
        <Flex direction="column" mb={50}>
          <Text fontSize="xl">{t('common:Title')}*</Text>
          <Input name="name" value={data.name} onChange={controlForm} outline="0px" mb={3} />
          <Text fontSize="xl">{t('common:SubCategory')}*</Text>
          <Box mb={5}>
            <WBSelect
              isMulti={false}
              name="product_subcategory_id"
              options={onFactorySelect(subCategoryList)}
              onChange={onSelectSubCategory}
              value={subCategories}
            />
          </Box>
          <Text fontSize="xl">{t('common:Brand')}</Text>
          <Input name="brand" value={data.brand} onChange={controlForm} outline="0px" mb={3} />
          <Text fontSize="xl">{t('common:ProductUrl')}*</Text>
          <Input name="url" onBlur={checkUrl} value={data?.url} onChange={controlForm} />
          {data?.url && !isValidURL(data?.url) && (
            <Text fontSize="sm" pt="5px" color="red">
              {t('ErrorURLHTTP')}
            </Text>
          )}
          <Text fontSize="xl">{t('common:Description')}*</Text>
          <Textarea
            minH={200}
            name="details"
            value={data.details}
            onChange={controlForm}
            mb={5}
            alignItems="center"
            outline="0px"
          />
          <Text fontSize="xl">{t('common:Trend')}</Text>
          <Checkbox mb={10} onChange={checkBox} minW="max-content" size="lg" colorScheme="orange" isChecked={isTrend} />

          <Text fontSize="xl">{t('common:Image')}*</Text>
          <Previews setFile={onSetFile} files={files?.file || []} removeFile={onRemoveFile} />
          {data?.icon && isEmpty(files.file) && (
            <Flex alignSelf="center">
              <Image src={data?.icon} width="200px" height="200px" />
            </Flex>
          )}
          <Flex justifyContent="flex-end" w="100%" backgroundColor="white" rounded={6}>
            <Button
              onClick={sendAddProduct}
              variant={isValidated ? 'secondaryOutline' : 'disabled'}
              size="primaryOutlineSmall"
              width="300px"
              isDisabled={!isValidated}
            >
              {t('common:Save')}
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </WBContainer>
  );
};
export default AddProductPage;
