export const ButtonStyles = {
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: '6px', // <-- border radius is same for all variants and sizes
    boxShadow: 'none !important',
    paddingLeft: '30px',
    paddingRight: '30px',
    outline: '0px',
    minWidth: '100px',
    width: '100%',
  },
  sizes: {
    primaryBig: {
      fontSize: 'lg',
      h: '54px',
    },
    primarySmall: {
      fontSize: 'lg',
      h: '40px',
    },
    primaryOutlineBig: {
      fontSize: 'lg',
      h: '54px',
    },
    primaryOutlineSmall: {
      fontSize: 'lg',
      h: '40px',
      'min-width': '140px',
    },
  },
  // Two variants: primary and outline
  variants: {
    disabled: {
      bg: 'white',
      color: 'disabled',
      borderWidth: '2px',
      borderColor: 'disabled',
    },

    primary: {
      bg: 'primary',
      color: 'white',
      px: '120px',
    },
    primaryOutline: {
      bg: 'white',
      color: 'primary',
      borderWidth: '2px',
      borderColor: 'primary',
    },
    secondaryOutline: {
      bg: 'white',
      color: 'secondary',
      borderWidth: '2px',
      borderColor: 'secondary',
      _hover: {
        bg: 'secondary',
        color: 'white',
        borderWidht: '2px',
      },
    },
    dangerOutline: {
      bg: 'white',
      color: 'dangerColor',
      borderWidth: '2px',
      borderColor: 'dangerColor',
      _hover: {
        bg: 'dangerColor',
        color: 'white',
        borderWidht: '2px',
      },
    },
    positiveOutline: {
      bg: 'white',
      color: 'positiveColor',
      borderWidth: '2px',
      borderColor: 'positiveColor',
      _hover: {
        bg: 'positiveColor',
        color: 'white',
        borderWidht: '2px',
      },
    },
    btnLink: {
      bg: 'trasparent',
      color: 'secondary',
      px: '120px',
      borderBottom: '1px solid transparent',
      padding: '0px',
      mt: '100px',
      borderRadius: '0px',
      _hover: {
        border: '0px',
        borderBottom: '1px solid',
        borderColor: 'secondary',
      },
    },
    btnUploadImage: {
      border: '1px dashed #d0d0d0',
      borderRadius: '6px',
      backgroundColor: '#fbfbfb',
      padding: '5px 20px',
      display: ' flex',
      alignItems: ' center',
      color: '#d0d0d0',
      minHeight: '200px',
      margin: '0',
      marginBottom: '50px',
      /* margin: "0px 10px" */
    },
    btnCirleRemove: {
      width: 'auto',
      position: 'absolute',
      top: '-10px',
      right: '-30px',
      borderRadius: '10em',
      padding: '2px 6px 3px',
      textDecoration: 'none',
      background: ' #555',
      border: '3px solid #fff',
      color: ' #fff',
      boxShadow: ' 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3)',
      textShadow: ' 0 1px 2px rgba(0, 0, 0, 0.5)',
      transition: 'background 0.5s',
    },
  },
  // The default size and variant values
  /*  defaultProps: {
    size: 'lg',
    variant: 'primary',
  }, */
};
