import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/reducers';

const allNotificationListSelector = (state: RootState) => state.notificationList;
export const allNotificationListLoading = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.loading,
);

export const loadingPostNotification = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.loadingPostNotification,
);
export const allNotificationList = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.notifies.elements,
);

export const allNotificationListTotalElement = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.notifies.total_elements,
);

export const allNotificationListCurrentPage = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.notifies.currentPage,
);

export const notificationDetailLoading = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.loadingNotification,
);

export const notificationDetail = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.notification,
);
export const editedNotification = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.loadingEditNotification,
);

export const filterNotificationLoading = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.loadingFilterNotification,
);
export const filterNotificationList = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.notifies.elements,
);
export const filterNotificationListTotalElement = createSelector(
  allNotificationListSelector,
  (notificationListState) => notificationListState.notifies.total_elements,
);
