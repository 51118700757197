import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { messageHandlerSet } from 'redux/messageHandler/actions';
import {
  getProgramDetailRequest,
  getProgramcDetailSuccess,
  getProgramDetailFailed,
  postAddProgramRequest,
  postAddProgramSuccess,
  postAddProgramFailed,
  patchProgramSuccess,
  patchProgramFailed,
  patchProgramRequest,
  getProgramIconSuccess,
  getProgramIconFailed,
  getProgramIconRequest,
  getEpisodeIconSuccess,
  getEpisodeIconFailed,
  getEpisodeIconRequest,
  getBroadCastListRequest,
  getBroadCastListSuccess,
  getBroadCastListFailed,
  patchEpisodeSuccess,
  patchEpisodeFailed,
  patchEpisodeRequest,
  deleteEpisodeSuccess,
  deleteEpisodeFailed,
  deleteEpisodeRequest,
  addEpisodeFailed,
  addEpisodeSuccess,
  addEpisodeRequest,
  getProductListSuccess,
  getProductListFailed,
  getProductListRequest,
  postAddTableJSONSuccess,
  postAddTableJSONRequest,
  deleteTableJSONSuccess,
  deleteTableJSONFailed,
  deleteTableJSONRequest,
} from './actions';
import * as FilmsAPI from './apiCall';
import {
  addEpisodePayloadRequest,
  deleteEpisodeRequestPayload,
  deleteTableJSONRequestPayload,
  getProgramDetailRequestPayload,
  patchEpisodeRequestPayload,
  patchProgramRequestPayload,
  postAddProgramRequestPayload,
  postAddTableJSONRequestPayload,
} from './types';
import i18n from '../../i18n';
import { getAllProgramListRequest } from 'redux/ProgramList/actions';

function* getProgramDetailSaga({ payload }: PayloadAction<getProgramDetailRequestPayload>) {
  try {
    const programDetailRes = yield call(FilmsAPI.getProgramDetail, { ...payload });
    yield put(getProgramcDetailSuccess(programDetailRes));
  } catch (err: any) {
    yield put(getProgramDetailFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noProgramDetailFound') }));
  }
}
function* postAddSaga({ payload }: PayloadAction<postAddProgramRequestPayload>) {
  try {
    yield call(FilmsAPI.postAddProgram, { ...payload });
    yield put(postAddProgramSuccess());
    yield put(getAllProgramListRequest({ limit: 999999 }));
  } catch (err: any) {
    yield put(postAddProgramFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithCreateProgram') }),
    );
  }
}
function* patchProgramSaga({ payload }: PayloadAction<patchProgramRequestPayload>) {
  try {
    const programRes = yield call(FilmsAPI.patchProgram, { ...payload });
    const { id } = programRes;

    yield put(patchProgramSuccess());
    yield put(getProgramDetailRequest({ id }));
  } catch (err: any) {
    yield put(patchProgramFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithEditProgram') }),
    );
  }
}
function* patchEpisodeSaga({ payload }: PayloadAction<patchEpisodeRequestPayload>) {
  try {
    yield call(FilmsAPI.patchEpisode, { ...payload });

    yield put(patchEpisodeSuccess());
    yield put(getProgramDetailRequest({ id: payload.episode.program_id }));
  } catch (err: any) {
    yield put(patchEpisodeFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithEditEpisode') }),
    );
  }
}
function* getProgramIconSaga() {
  try {
    const programIconRes = yield call(FilmsAPI.getProgramIcon);

    yield put(getProgramIconSuccess({ ...programIconRes }));
  } catch (err: any) {
    yield put(getProgramIconFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noProgramIconFound') }));
  }
}
function* getEpisodeIconSaga() {
  try {
    const episodeIconRes = yield call(FilmsAPI.getEpisodeIcon);

    yield put(getEpisodeIconSuccess({ ...episodeIconRes }));
  } catch (err: any) {
    yield put(getEpisodeIconFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noEpisodeIconFound') }));
  }
}
function* getBroadCastListSaga() {
  try {
    const broadCastListRes = yield call(FilmsAPI.getBroadCastList);

    yield put(getBroadCastListSuccess({ ...broadCastListRes }));
  } catch (err: any) {
    yield put(getBroadCastListFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noBroadcastListFound') }));
  }
}
function* addEpisodeSaga({ payload }: PayloadAction<addEpisodePayloadRequest>) {
  try {
    yield call(FilmsAPI.addEpisode, { ...payload });
    yield put(addEpisodeSuccess());
    yield put(getProgramDetailRequest({ id: payload.program_id }));
  } catch (err: any) {
    yield put(addEpisodeFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithCreateEpisode') }),
    );
  }
}

function* deleteEpisodeSaga({ payload }: PayloadAction<deleteEpisodeRequestPayload>) {
  try {
    yield call(FilmsAPI.deleteEpisode, { ...payload });

    yield put(deleteEpisodeSuccess());
    yield put(getProgramDetailRequest({ id: payload.programId }));
  } catch (err: any) {
    yield put(deleteEpisodeFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithDeleteEpisode') }),
    );
  }
}
function* getProductListSaga() {
  try {
    const programListRes = yield call(FilmsAPI.getProducts);

    yield put(getProductListSuccess({ ...programListRes }));
  } catch (err: any) {
    yield put(getProductListFailed());
    yield put(messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('noProductListFound') }));
  }
}
function* postTableJSONSaga({ payload }: PayloadAction<postAddTableJSONRequestPayload>) {
  try {
    yield call(FilmsAPI.postTableJson, { ...payload });

    yield put(postAddTableJSONSuccess());
  } catch (err: any) {
    yield put(postAddProgramFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemWithCreatePlacement') }),
    );
  }
}
function* deleteTableJsonSaga({ payload }: PayloadAction<deleteTableJSONRequestPayload>) {
  try {
    yield call(FilmsAPI.deleteTableJson, { ...payload });
    const id = payload.programId;
    yield put(deleteTableJSONSuccess());
    yield put(getProgramDetailRequest({ id }));
  } catch (err: any) {
    yield put(deleteTableJSONFailed());
    yield put(
      messageHandlerSet({ message: err?.message, type: 'error', description: i18n.t('problemDeletePlacement') }),
    );
  }
}

function* programDetailSaga() {
  yield takeLatest(getProgramDetailRequest.type, getProgramDetailSaga);
  yield takeLatest(postAddProgramRequest.type, postAddSaga);
  yield takeLatest(patchProgramRequest.type, patchProgramSaga);
  yield takeLatest(getProgramIconRequest.type, getProgramIconSaga);
  yield takeLatest(getEpisodeIconRequest.type, getEpisodeIconSaga);
  yield takeLatest(getBroadCastListRequest.type, getBroadCastListSaga);
  yield takeLatest(patchEpisodeRequest.type, patchEpisodeSaga);
  yield takeLatest(addEpisodeRequest.type, addEpisodeSaga);
  yield takeLatest(deleteEpisodeRequest.type, deleteEpisodeSaga);
  yield takeLatest(getProductListRequest.type, getProductListSaga);
  yield takeLatest(postAddTableJSONRequest.type, postTableJSONSaga);
  yield takeLatest(deleteTableJSONRequest.type, deleteTableJsonSaga);
}

export default programDetailSaga;
