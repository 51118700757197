import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Flex, Text, Input, Textarea, Box, IconButton, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import format from 'date-fns/format';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTableJSONRequest,
  getProductListRequest,
  getProgramDetailRequest,
  patchEpisodeRequest,
} from 'redux/ProgramDetail/actions';
import WBEditableTable from 'components/WBEditableTable';
import WBSelect from 'components/WBSelect';
import EditableCell from 'components/WBEditableTable/WBEditableCell';
import {
  deleteTableLoading,
  episodeDetails,
  loadingEpisodeEdit,
  productList,
  programDetailLoading,
} from 'redux/ProgramDetail/selectors';
import Previews from 'components/WBDropzonePreview';
import { computeChecksumMd5 } from 'utils';
import { AiFillDelete } from 'react-icons/ai';
import { palette } from 'theme/theme';
import { isEmpty } from 'lodash';
import WBLoader from 'components/WBLoader';
import WBContainer from 'components/WBContainer';
import { BiArrowBack } from 'react-icons/bi';
import { history } from 'routes/history';
import { addMonths } from 'date-fns';

const EditEpisodePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = useParams();

  const products = useSelector(productList);
  const deletePlacementLoading = useSelector(deleteTableLoading);
  const episodes: any = useSelector(episodeDetails);
  const loading = useSelector(programDetailLoading);
  const episodeEditedLoading = useSelector(loadingEpisodeEdit);

  const [data, setData] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [rowdata, setRowData] = useState<any>([]);
  const [skipPageReset, setSkipPageReset] = useState(false);

  const isFormValid = !!(
    data?.name &&
    data?.description &&
    data?.start_at &&
    data?.video_url &&
    (data?.icon_url || !isEmpty(files))
  );
  const isPlacementValid = (rowdata: any) => {
    if (!isEmpty(rowdata)) {
      return !!(
        rowdata[0]?.send_at &&
        rowdata[0]?.notification_type_id?.value &&
        rowdata[0]?.title &&
        rowdata[0]?.description &&
        rowdata[0]?.product_id?.value
      );
    } else {
      return false;
    }
  };
  const isFormValidWithPlacement = !!(
    data?.name &&
    data?.description &&
    data?.start_at &&
    data?.video_url &&
    !isEmpty(rowdata) &&
    !!isPlacementValid(rowdata)
  );

  const isValidated = isFormValidWithPlacement && isFormValid;

  const chooseDay = useCallback(
    (e) => {
      setData({ ...data, start_at: e });
    },
    [data],
  );

  const onSetFile = useCallback(async (file) => {
    const blob = new Blob(file, { type: 'text/plain' });
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    const hash = await computeChecksumMd5(file);
    reader.onloadend = function () {
      const base64String = reader?.result?.toString().split(',')[1];
      setFiles({ file, base64String, hash });
    };
  }, []);

  const onRemoveFile = useCallback(() => {
    setFiles([]);
  }, []);

  const goBack = useCallback(() => {
    history.goBack();
  }, []);

  const sendEditImage = useCallback(() => {
    if (!isEmpty(files.file)) {
      return {
        files: [
          {
            filename: !isEmpty(!files.file) && files?.file[0]?.path,
            hash: files?.hash,
            filestream: files?.base64String,
          },
        ],
      };
    } else {
      return null;
    }
  }, [files?.base64String, files.file, files?.hash]);

  const sendEditEpisode = useCallback(() => {
    const editEpisodeObject = {
      name: data?.name,
      description: data?.description,
      start_at: format(data?.start_at, 'dd-MM-yyyy HH:mm') as unknown as Date,
      end_at: addMonths(new Date(data?.start_at), 4) as unknown as Date,
      program_id: Number(param.id),
      video_url: data?.video_url,
      icon: sendEditImage(),
    };

    const placements = rowdata?.map((sendTable: any) => {
      return {
        product_placement_id: sendTable.product_placement_id,
        product_id: sendTable?.product_id?.value,
        owner_id: 1,
        send_at: sendTable?.send_at?.substr(0, 8),
        notification_type_id: sendTable?.notification_type_id?.value,
        title: sendTable?.title,
        description: sendTable?.description,
      };
    });
    dispatch(patchEpisodeRequest({ id: param.episodeId, episode: { ...editEpisodeObject, placement: placements } }));
  }, [
    data?.description,
    data?.name,
    data?.start_at,
    data?.video_url,
    dispatch,
    param.episodeId,
    param.id,
    rowdata,
    sendEditImage,
  ]);

  const controlForm = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const onAddRowClick = useCallback(() => {
    setRowData(rowdata.concat({ title: '', description: '', notification_type_id: '', product_id: '', send_at: '' }));
  }, [rowdata]);

  const updateMyData = useCallback((rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setRowData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      }),
    );
  }, []);

  const onDeletePlacementFETCH = useCallback(
    (id: any) => {
      dispatch(deleteTableJSONRequest({ id, programId: param.id }));
    },
    [dispatch, param.id],
  );

  const onDeletePlacement = useCallback(
    (idx: any) => {
      let rows = [...rowdata];
      rows.splice(idx, 1);
      setRowData(rows);
    },
    [rowdata],
  );

  const notification_type_id = useMemo(
    () => [
      { label: 'BRAND', value: 1 },
      { label: 'LIKE', value: 2 },
      { label: 'SUGGESTION', value: 3 },
      { label: 'SPONSORSHIP', value: 4 },
    ],
    [],
  );

  useEffect(() => {
    if (param.episodeId) {
      dispatch(getProgramDetailRequest({ id: param.id }));
      dispatch(getProductListRequest());
    }
  }, [dispatch, param.id, param.episodeId]);

  useEffect(() => {
    const idsNotification = notification_type_id?.map((not) => not.value);
    const idsProducts = products?.map((prod) => prod.value);
    const newPlacement: any[] = [];
    const pl: any = [];
    let newArr: any = {};

    episodes?.forEach((ep) => {
      if (ep.id === Number(param.episodeId)) {
        newArr = { ...ep, start_at: new Date(ep?.start_at) };
      }
    });
    if (param.episodeId) {
      for (let idx = 0; idx < newArr?.placement?.length; idx++) {
        for (let j = 0; j < idsNotification?.length; j++) {
          if (newArr.placement[idx]?.notification_type_id === idsNotification[j]) {
            newPlacement.push({
              ...newArr.placement[idx],
              notification_type_id: notification_type_id[j],
            });
          }
        }
      }

      for (let idx = 0; idx <= newPlacement?.length; idx++) {
        for (let k = 0; k < idsProducts?.length; k++) {
          if (Number(newPlacement[idx]?.product_id) === Number(idsProducts[k])) {
            pl.push({
              ...newPlacement[idx],
              product_id: products[k],
            });
          }
        }
      }
    }

    setData({ ...newArr, placement: pl });
    setRowData(pl);
  }, [episodes, notification_type_id, param.episodeId, products]);

  useEffect(() => {
    setSkipPageReset(false);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: (d) => `${d?.title}`,
        id: 'title',
        disableSortBy: true,
        Cell: EditableCell,
      },
      {
        Header: 'Description',
        accessor: 'description',
        disableSortBy: false,
        Cell: (allprops: any) => {
          return <EditableCell type="textArea" {...allprops} />;
        },
      },
      {
        Header: 'Notification type',
        accessor: 'notification_type_id',
        disableSortBy: false,
        Cell: ({ value: initialValue, row: { index }, column: { id } }) => {
          const onItemClick = (value) => {
            updateMyData(index, id, value);
          };
          return (
            <WBSelect
              isMulti={false}
              name="notification_type_id"
              options={notification_type_id}
              onChange={onItemClick}
              value={initialValue}
            />
          );
        },
      },
      {
        Header: 'Send At',
        accessor: 'send_at',
        Cell: (allprops: any) => {
          return <EditableCell maxWidth="100px" type="time" {...allprops} />;
        },
      },
      {
        Header: 'Products',
        accessor: 'product_id',
        Cell: ({ value: initialValue, row: { index }, column: { id }, updateMyData }) => {
          const onItemClick = (value) => {
            updateMyData(index, id, value);
          };
          return (
            <WBSelect
              isMulti={false}
              name="products"
              options={[...products]}
              onChange={onItemClick}
              value={initialValue}
            />
          );
        },
      },
      {
        Header: 'Action',
        accessor: 'action',
        Cell: ({ row }) => {
          return (
            <IconButton
              m={2}
              outline="0px"
              variant="outline"
              color={palette.POMEGRANATE}
              border="1px solid"
              borderColor={palette.POMEGRANATE}
              borderRadius="6px"
              aria-label="delete"
              maxW={25}
              icon={<AiFillDelete />}
              onClick={
                row?.original?.product_placement_id
                  ? () => onDeletePlacementFETCH(row?.original?.product_placement_id)
                  : () => onDeletePlacement(row.index)
              }
            />
          );
        },
      },
    ],
    [notification_type_id, updateMyData, products, onDeletePlacementFETCH, onDeletePlacement],
  );

  if (loading && episodeEditedLoading) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        position="absolute"
        top={0}
        bottom={0}
        left={0}
        right={0}
        backgroundColor="shadowGreyCard4"
      >
        <WBLoader />
      </Flex>
    );
  }
  return (
    <WBContainer>
      <Flex alignItems="center" w="100%" backgroundColor="white" rounded={6} mb={5}>
        <IconButton
          outline="0px"
          variant="outline"
          color={palette.ORANGE}
          aria-label="delete"
          maxW={25}
          icon={<BiArrowBack />}
          onClick={goBack}
        />
        <Text padding={1} color={palette.ORANGE} fontSize="xl">
          {t('common:GoBack')}
        </Text>
      </Flex>
      <Flex direction="column" mb={50}>
        <Text fontSize="xl">{t('common:Title')}*</Text>
        <Input name="name" value={data?.name} onChange={controlForm} outline="0px" mb={3} />
        <Text fontSize="xl">{t('common:Description')}*</Text>
        <Textarea
          minH={200}
          name="description"
          value={data?.description}
          onChange={controlForm}
          mb={5}
          alignItems="center"
          outline="0px"
        />
        <Text fontSize="xl">{t('common:Video')}*</Text>
        <Input name="video_url" value={data?.video_url} onChange={controlForm} outline="0px" mb={3} />
        <Text fontSize="xl" pt="20px">
          {t('common:ImageProduct')}*
        </Text>
        <Previews setFile={onSetFile} files={files?.file} removeFile={onRemoveFile} />

        {data?.icon_url && isEmpty(files.file) && (
          <Flex alignSelf="center">
            <Image src={data?.icon_url} width="200px" height="200px" />
          </Flex>
        )}
        <Box mb={10} width="100%">
          <Text fontSize="xl">{t('common:Date')}*</Text>
          <DateTimePicker onChange={chooseDay} value={data?.start_at} />
        </Box>

        <Box>
          <Box>
            <Flex justifyContent="flex-end" mt={10} mb={10} w="100%" backgroundColor="white" rounded={6}>
              <Button onClick={onAddRowClick} variant="secondaryOutline" size="primaryOutlineSmall" width="300px">
                {t('AddNewPlacement')}
              </Button>
            </Flex>
            {!isEmpty(rowdata) && (
              <Box mt={10} mb={10}>
                {!deletePlacementLoading && !loading ? (
                  <WBEditableTable
                    className="stripedTable"
                    columns={columns}
                    data={rowdata}
                    updateMyData={updateMyData}
                    skipPageReset={skipPageReset}
                  />
                ) : (
                  <WBLoader />
                )}
              </Box>
            )}
          </Box>
          {/*  )} */}
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" w="100%" backgroundColor="white" rounded={6} mb={5}>
        <Button
          onClick={sendEditEpisode}
          variant={isValidated ? 'secondaryOutline' : 'disabled'}
          size="primaryOutlineSmall"
          width="300px"
          isDisabled={!isValidated}
        >
          {t('common:Save')}
        </Button>
      </Flex>
    </WBContainer>
  );
};
export default EditEpisodePage;
