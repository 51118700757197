import { Box } from '@chakra-ui/react';
import React from 'react';
import { useTable } from 'react-table';

const Table = ({ columns, data, className, updateMyData, skipPageReset }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    updateMyData,
    autoResetPage: !skipPageReset,
  });

  return (
    <Box {...getTableProps()} className={className} as="table" width="100%">
      <Box as="thead">
        {headerGroups.map((headerGroup) => (
          <Box {...headerGroup.getHeaderGroupProps()} as="tr">
            {headerGroup.headers.map((column) => (
              <Box as="th" {...column.getHeaderProps()}>
                {column.render('Header')}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box {...getTableBodyProps()} as="tbody">
        {rows.map((row) => {
          prepareRow(row);
          return (
            <Box {...row.getRowProps()} as="tr" backgroundColor="white" boxShadow={`0 0 5px 2px grey`}>
              {row.cells.map((cell) => {
                return (
                  <Box textAlign="center" as="td" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </Box>
                );
              })}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default Table;
