import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Heading, Button, Text, Box, IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import WBContainer from 'components/WBContainer';
import {
  allNotificationListCurrentPage,
  allNotificationListLoading,
  allNotificationListTotalElement,
  filterNotificationList,
  filterNotificationLoading,
} from 'redux/Notification/selectors';
import { history } from 'routes/history';
import { AppRoutes } from 'routes/routesList';
import PUTable from 'components/WBTable';
import { STATUS_CONSTANTS } from 'utils/types';
import {
  deleteNotificationRequest,
  filterNotificationRequest,
  getAllNotificationListRequest,
  postNewNotificationByIdRequest,
} from 'redux/Notification/actions';
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai';
import { palette } from 'theme/theme';
import { FiSend } from 'react-icons/fi';
import WBSelect from 'components/WBSelect';
import { allProgramList, allProgramListLoading } from 'redux/ProgramList/selectors';
import { getAllProgramListRequest } from 'redux/ProgramList/actions';
import { isEmpty } from 'lodash';

const Notification: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notificationList = useSelector(filterNotificationList);
  const loadingNotification = useSelector(allNotificationListLoading);
  const loadingFilteredNotification = useSelector(filterNotificationLoading);
  const totalElements = useSelector(allNotificationListTotalElement);
  const currentPage = useSelector(allNotificationListCurrentPage);
  const fetchIdRef = useRef(0);
  const programList = useSelector(allProgramList);
  const programListLoading = useSelector(allProgramListLoading);
  const [selectedProduct, setSelectedProgram] = useState<{ label: string; value: number }>({ label: 'all', value: 0 });

  const convertStatus = useCallback(
    (type: number) => {
      switch (type) {
        case STATUS_CONSTANTS.SENT:
          return (
            <Box borderRadius="6px" padding="5px 10px" backgroundColor="green" color="white">
              {t('common:Sent')}
            </Box>
          );
        case STATUS_CONSTANTS.DRAFT:
          return (
            <Box borderRadius="6px" padding="5px 10px" backgroundColor="gray.600" color="white">
              {t('common:Draft')}
            </Box>
          );
        case STATUS_CONSTANTS.DELETED:
          return (
            <Box borderRadius="6px" padding="5px 10px" backgroundColor="red" color="white">
              {t('common:Deleted')}
            </Box>
          );
        default:
          return (
            <Box borderRadius="6px" padding="5px 10px" backgroundColor="gray.200" color="white">
              {t('common:Draft')}
            </Box>
          );
      }
    },
    [t],
  );

  const onFactorySelect = useCallback((list) => {
    const program = !isEmpty(list)
      ? list.map((l) => {
          return {
            value: l?.program_id,
            label: `${l.program_name} - ${l?.name}`,
          };
        })
      : [];

    return [{ value: 0, label: 'all' }, ...program];
  }, []);

  const onSelectProgram = useCallback(
    (select) => {
      if (!isEmpty(select)) {
        if (select?.value === 0) {
          dispatch(getAllNotificationListRequest({ limit: 10 }));
        } else {
          dispatch(filterNotificationRequest({ limit: 10, page: 1, id: select?.value }));
        }
        setSelectedProgram(select);
      } else {
        return [];
      }
    },
    [dispatch],
  );

  const sendNotification = useCallback(
    (id: string) => {
      dispatch(postNewNotificationByIdRequest({ id }));
    },
    [dispatch],
  );

  const onDeleteNotification = useCallback(
    (id: number) => {
      dispatch(deleteNotificationRequest({ id }));
    },
    [dispatch],
  );

  const goToEditNotification = useCallback((e: React.MouseEvent, value) => {
    e.stopPropagation();
    history.push(`${AppRoutes.EditNotification}/${Number(value)}/edit`);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: <Text textStyle="tableTH"> {t('common:Title')}</Text>,
        accessor: 'title',
        Cell: (cell) => {
          return (
            <Flex justifyContent="start" height="100%" alignItems="center">
              {cell?.row?.original?.title || '-'}
            </Flex>
          );
        },
      },
      {
        Header: <Text textStyle="tableTH"> {t('common:Description')}</Text>,
        accessor: 'description',
        Cell: (cell) => {
          return (
            <Flex justifyContent="start" height="100%" alignItems="center">
              {cell?.row?.original?.description || '-'}
            </Flex>
          );
        },
      },
      {
        Header: <Text textStyle="tableTH"> {t('common:ProgramName')}</Text>,
        accessor: 'program_name',
        Cell: (cell) => {
          return (
            <Flex justifyContent="start" height="100%" alignItems="center">
              {cell?.row?.original?.program_name || '-'}
            </Flex>
          );
        },
      },
      {
        Header: <Text textStyle="tableTH"> {t('common:Note')}</Text>,
        accessor: 'note',
        Cell: (cell) => {
          return (
            <Flex justifyContent="start" height="100%" alignItems="center">
              {cell?.row?.original?.note || '-'}
            </Flex>
          );
        },
      },
      {
        Header: <Text textStyle="tableTH"> {t('common:Status')}</Text>,
        accessor: 'status',
        Cell: (cell) => {
          return (
            <Flex justifyContent="start" height="100%" alignItems="center">
              {convertStatus(cell?.row?.original?.status)}
            </Flex>
          );
        },
      },
      {
        Header: <Text textStyle="tableTH"> {t('common:Actions')}</Text>,
        accessor: 'action',
        filterable: false,
        disableSortBy: true,
        Cell: (cell) => {
          return (
            <Flex width="auto" boxSize="content-box" justifyContent="center" height="100%" alignItems="center">
              <IconButton
                onClick={() => sendNotification(cell?.row?.original?.product_placement_id)}
                m={2}
                outline="0px"
                variant="outline"
                color={palette.BBLUE}
                border="1px solid"
                borderColor={palette.BBLUE}
                borderRadius="6px"
                aria-label="SendNotification"
                maxW={100}
                icon={<FiSend />}
              >
                {t('common:SendNotification')}
              </IconButton>

              <IconButton
                onClick={(e) => goToEditNotification(e, cell?.row?.original?.product_placement_id)}
                m={2}
                outline="0px"
                variant="outline"
                color={palette.ORANGE_OBI}
                border="1px solid"
                borderColor={palette.ORANGE_OBI}
                borderRadius="6px"
                aria-label="Open Edit"
                maxW={100}
                icon={<AiOutlineEdit />}
              >
                {t('common:EditNotification')}
              </IconButton>

              {cell?.row?.original?.status !== STATUS_CONSTANTS.SENT && (
                <IconButton
                  m={2}
                  outline="0px"
                  variant="outline"
                  color={palette.POMEGRANATE}
                  border="1px solid"
                  borderColor={palette.POMEGRANATE}
                  borderRadius="6px"
                  aria-label="Delete"
                  maxW={100}
                  icon={<AiFillDelete />}
                  onClick={() => onDeleteNotification(cell?.row?.original?.product_placement_id)}
                />
              )}
            </Flex>
          );
        },
      },
    ],
    [convertStatus, goToEditNotification, onDeleteNotification, sendNotification, t],
  );

  const onCreateNewNotification = useCallback(() => {
    history.push(AppRoutes.CreateNotification);
  }, []);

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      if (fetchId === fetchIdRef.current && selectedProduct?.value !== 0 && selectedProduct?.value !== undefined) {
        dispatch(
          filterNotificationRequest({
            limit: pageSize,
            page: pageIndex + 1,
            id: selectedProduct?.value,
          }),
        );
      }
      if (fetchId === fetchIdRef.current && (selectedProduct?.value === 0 || selectedProduct?.value === undefined)) {
        dispatch(
          getAllNotificationListRequest({
            limit: pageSize,
            page: pageIndex + 1,
          }),
        );
      }
    },
    [dispatch, selectedProduct?.value],
  );

  useEffect(() => {
    dispatch(getAllProgramListRequest({ limit: 10 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllNotificationListRequest({ limit: 10 }));
  }, [dispatch]);

  return (
    <WBContainer>
      <Flex justifyContent="space-between">
        <Heading mb={6}>{t('Notification')}</Heading>
        <Button onClick={onCreateNewNotification} variant="secondaryOutline" size="primaryOutlineSmall" w={100}>
          {t('Create')}
        </Button>
      </Flex>
      <Flex flexDirection="column" mb={50} w={300}>
        <Text fontSize="xl" pt="20px">
          {t('common:FilterProgram')}
        </Text>
        {!programListLoading ? (
          <WBSelect
            isMulti={false}
            name="program_id"
            options={onFactorySelect(programList)}
            onChange={onSelectProgram}
            value={selectedProduct}
          />
        ) : (
          <Text fontSize="xl" pt="20px">
            {t('common:NoProgramFound')}
          </Text>
        )}
      </Flex>
      <PUTable
        columns={columns}
        data={notificationList || []}
        loading={loadingNotification || loadingFilteredNotification}
        totalPages={totalElements}
        currentPage={currentPage}
        fetchData={fetchData}
        className="stripedTable"
      />
    </WBContainer>
  );
};

export default Notification;
