import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'redux/reducers';

const allProductListSelector = (state: RootState) => state?.productList;

export const allProductListLoading = createSelector(
  allProductListSelector,
  (productListState) => productListState.loading,
);
export const allBrandsLoading = createSelector(
  allProductListSelector,
  (productListState) => productListState.loadingBrands,
);
export const allSubCategoryLoading = createSelector(
  allProductListSelector,
  (productListState) => productListState.loadingSubCategoryList,
);

export const allProductList = createSelector(
  allProductListSelector,
  (productListState) => productListState.productList,
);
export const productDetails = createSelector(allProductListSelector, (productListState) => {
  return productListState.productDetail;
});
export const loadingProductDetails = createSelector(
  allProductListSelector,
  (productListState) => productListState.loadingProductDetail,
);
export const loadingAddProduct = createSelector(
  allProductListSelector,
  (productListState) => productListState.loadingAddProduct,
);
export const allProductListElement = createSelector(
  allProductListSelector,
  (productListState) => productListState.total_elements,
);
export const allProductListCurrentPage = createSelector(
  allProductListSelector,
  (productListState) => productListState.currentPage,
);

export const deleteProductLoading = createSelector(
  allProductListSelector,
  (productListState) => productListState.loadingDeleteProduct,
);

export const allBrandList = createSelector(allProductListSelector, (productListState) => {
  return productListState.brandList;
});
export const allSubCategoryList = createSelector(
  allProductListSelector,
  (productListState) => productListState.subCategoryList,
);
