import { useProvideAuthContext } from 'auth/hooks/useProvideAuthContext';
import { authContext } from 'auth/utils/authContext';
import * as React from 'react';

// export interface AuthProviderProps {}

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuthContext();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};
