import { Input, Textarea } from '@chakra-ui/react';
import React from 'react';
import TimeField from 'react-simple-timefield';

const EditableCell = ({
  maxWidth = 'auto',
  minWidth = 'auto',
  width = '95%',
  type = '',
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (type === 'textArea') {
    return <Textarea value={value} onChange={onChange} onBlur={onBlur} name={id} rows={1} />;
  } else if (type === 'time') {
    return (
      <TimeField
        onChange={onChange}
        value={value}
        colon=":"
        showSeconds
        input={
          <Input
            maxWidth={maxWidth}
            minWidth={minWidth}
            onBlur={onBlur}
            name={id}
            type="text"
            focusBorderColor="gray.300"
            size="md"
            w={width}
            fontSize="md"
            mr="5px"
            pl="0.3rem"
            pr="0.3rem"
            textAlign="center"
            /*   isInvalid={!isEmpty(errors?.time_table) && errors?.time_table[Number(index)]?.timeFrom}
    disabled={isClosed(Number(index))}
    {...register(`time_table.${Number(index)}.timeFrom`)} */
          />
        }
      />
    );
  } else {
    return <Input value={value} onChange={onChange} onBlur={onBlur} name={id} />;
  }
};

export default EditableCell;
